import { useMemo, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// @mui material components
import { Icon, Table as MuiTable } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftTypography from "components/SoftTypography";

// Kaizen Dashboard base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import { makeNiceText } from "utils/formatters";
import MobileRow from "components/Tables/MobileRow";

function DataTable({ columns, rows, order, setOrder }) {
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;

  const renderColumns = columns.map(({ name, desc, align, width, noOrder, orderField }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns.length - 1) {
      pl = 1;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SoftBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        onClick={!noOrder ? () => (orderField ? setOrder(orderField) : setOrder(name)) : null}
        sx={{ cursor: !noOrder ? "pointer" : "default" }}
      >
        <SoftBox
          display="flex"
          alignItems="center"
          justifyContent={align == "left" ? "flex-start" : align == "right" ? "flex-end" : "center"}
        >
          <SoftTypography
            variant="p"
            fontWeight="bold"
            color="dark"
            sx={{
              textWrap: "nowrap",
            }}
          >
            {desc ? desc.toUpperCase() : name.toUpperCase()}
            {!noOrder && (order?.campo === name || order?.campo === orderField) && (
              <Icon
                fontSize="medium"
                sx={{
                  verticalAlign: "middle",
                }}
              >
                {order?.orden === "ASC" ? "arrow_drop_up" : "arrow_drop_down"}
              </Icon>
            )}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    );
  });

  const renderRows = rows.map((row, key) => {
    const rowKey = `row-${key}`;

    const tableRow = columns.map(({ name, align }) => {
      let template;

      if (Array.isArray(row[name])) {
        template = (
          <SoftBox
            key={uuidv4()}
            component="td"
            p={1}
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
          >
            <SoftBox display="flex" alignItems="center" py={0.5} px={1}>
              <SoftBox mr={2}>
                <SoftAvatar src={row[name][0]} name={row[name][1]} variant="rounded" size="sm" />
              </SoftBox>
              <SoftTypography variant="button" fontWeight="medium" sx={{ width: "max-content" }}>
                {row[name][1]}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        );
      } else {
        template = (
          <SoftBox
            key={uuidv4()}
            component="td"
            p={1}
            textAlign={align}
            borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
          >
            <SoftTypography
              variant="button"
              fontWeight="regular"
              color="secondary"
              sx={() => {
                row[name].props.image
                  ? { display: "inline-block", width: "max-content", verticalAlign: "text-top" }
                  : { display: "inline-block", width: "max-content" };
              }}
            >
              {row[name]}
            </SoftTypography>
          </SoftBox>
        );
      }

      return template;
    });

    return (
      <TableRow key={rowKey} hover>
        {tableRow}
      </TableRow>
    );
  });

  const renderRowsMobile = rows.map((row, key) => {
    const rowKey = `row-${key}`;

    let data = columns.map(({ name, align, desc }) => {
      return {
        columna: desc || name,
        contenido: row[name],
        valor: row[name].props.data || null,
      };
    });

    data = data.filter((item) => item.valor);

    if (data?.length > 3 && data.filter((item) => item.valor).length >= 3) {
      return (
        <MobileRow
          key={rowKey}
          icono={data[0].valor}
          titulo={data[2].valor}
          codigo={data[1].valor}
          descripcion={data[3].valor}
          funcion={data[0].contenido.props.onClick || null}
          nombreDescripcion={data[3].columna}
        />
      );
    } else {
      const tableRow = columns.map(({ name, align }) => {
        let template;

        if (Array.isArray(row[name])) {
          template = (
            <SoftBox
              key={uuidv4()}
              component="td"
              p={1}
              borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
            >
              <SoftBox display="flex" alignItems="center" py={0.5} px={1}>
                <SoftBox mr={2}>
                  <SoftAvatar src={row[name][0]} name={row[name][1]} variant="rounded" size="sm" />
                </SoftBox>
                <SoftTypography variant="button" fontWeight="medium" sx={{ width: "max-content" }}>
                  {row[name][1]}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          );
        } else {
          template = (
            <SoftBox
              key={uuidv4()}
              component="td"
              p={1}
              textAlign={align}
              borderBottom={row.hasBorder ? `${borderWidth[1]} solid ${light.main}` : null}
            >
              <SoftTypography
                variant="button"
                fontWeight="regular"
                color="secondary"
                sx={() => {
                  row[name].props.image
                    ? { display: "inline-block", width: "max-content", verticalAlign: "text-top" }
                    : { display: "inline-block", width: "max-content" };
                }}
              >
                {row[name]}
              </SoftTypography>
            </SoftBox>
          );
        }

        return template;
      });

      return (
        <TableRow key={rowKey} hover>
          {tableRow}
        </TableRow>
      );
    }
  });

  return useMemo(
    () => (
      <TableContainer
        sx={{
          borderRadius: 0,
        }}
      >
        <MuiTable
          sx={{
            display: { xs: "none", sm: "block" },
          }}
        >
          <SoftBox component="thead">
            <TableRow>{renderColumns}</TableRow>
          </SoftBox>
          <TableBody>{renderRows}</TableBody>
        </MuiTable>
        <SoftBox sx={{ display: { xs: "block", sm: "none" } }}>{renderRowsMobile}</SoftBox>
      </TableContainer>
    ),
    [columns, rows, order]
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  columns: [],
  rows: [{}],
  setOrder: () => {},
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  desc: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
  order: PropTypes.object,
  setOrder: PropTypes.func,
};

export default DataTable;
