export const formatDateARG = (date) => {
  // get yyyy-mm-dd and return dd-mm-yyyy
  const dateArray = date.split("-");
  return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
};

export const formatDateTimeARG = (date) => {
  // get yyyy-mm-dd hh:mm:ss and return dd-mm-yyyy hh:mm
  const dateArray = date.split(" ");
  const datePart = dateArray[0].split("-");
  const timePart = dateArray[1].split(":");
  return `${datePart[2]}-${datePart[1]}-${datePart[0]} ${timePart[0]}:${timePart[1]}`;
};

const str2datetime = (str) => {
  let dateArr = [];
  let timeArr = [];
  if (str.includes(" ")) {
    dateArr = str.split(" ")[0].split("-");
    timeArr = str.split(" ")[1].split(":");
  } else {
    dateArr = str.split("-");
  }

  //must use local time
  const date = new Date();
  date.setFullYear(dateArr[0], dateArr[1] - 1, dateArr[2]);
  date.setHours(timeArr[0] || 0, timeArr[1] || 0, timeArr[2] || 0);
  return date;
};

export const formatDate = (dateTime, format) => {
  if (!dateTime) return "";
  if (typeof dateTime === "string") {
    dateTime = str2datetime(dateTime);
  }
  const now = new Date();
  const newDateTime = new Date(dateTime);
  newDateTime.setHours(0, 0, 0, 0);
  const timeDifference = now - newDateTime;
  const oneDay = 24 * 60 * 60 * 1000;
  const oneWeek = 7 * oneDay;
  const oneMonth = 30 * oneDay;
  const oneYear = 365 * oneDay;

  const isFuture = timeDifference < 0;

  if (format.includes("XXXX")) {
    if (Math.abs(timeDifference) < oneDay && now.getDate() === newDateTime.getDate()) {
      format = format.replace("XXXX", "hoy");
    } else if (
      Math.abs(timeDifference) < 2 * oneDay &&
      Math.abs(now.getDate() - newDateTime.getDate()) === 1
    ) {
      format = format.replace("XXXX", isFuture ? "mañana" : "ayer");
    } else if (Math.abs(timeDifference) < oneWeek) {
      const daysAgo = Math.abs(Math.floor(timeDifference / oneDay));
      format = format.replace(
        "XXXX",
        `${isFuture ? "dentro de" : "hace"} ${daysAgo} día${daysAgo !== 1 ? "s" : ""}`
      );
    } else if (Math.abs(timeDifference) < 2 * oneWeek) {
      format = format.replace("XXXX", isFuture ? "la semana que viene" : "la semana pasada");
    } else if (timeDifference < oneMonth) {
      const weeksAgo = Math.abs(Math.floor(timeDifference / oneWeek));
      format = format.replace(
        "XXXX",
        `${isFuture ? "dentro de" : "hace"} ${weeksAgo} semana${weeksAgo !== 1 ? "s" : ""}`
      );
    } else if (Math.abs(timeDifference) < 2 * oneMonth) {
      format = format.replace("XXXX", isFuture ? "el mes que viene" : "el mes pasado");
    } else if (Math.abs(timeDifference) < oneYear) {
      const monthsAgo = Math.abs(Math.floor(timeDifference / oneMonth));
      format = format.replace(
        "XXXX",
        `${isFuture ? "dentro de" : "hace"} ${monthsAgo} mes${monthsAgo !== 1 ? "es" : ""}`
      );
    } else if (Math.abs(timeDifference) < 2 * oneYear) {
      format = format.replace("XXXX", isFuture ? "el año que viene" : "el año pasado");
    } else {
      const yearsAgo = Math.abs(Math.floor(timeDifference / oneYear));
      format = format.replace(
        "XXXX",
        `${isFuture ? "dentro de" : "hace"} ${yearsAgo} año${yearsAgo !== 1 ? "s" : ""}`
      );
    }
  }

  const year = dateTime.getFullYear();
  const month = String(dateTime.getMonth() + 1).padStart(2, "0");
  const day = String(dateTime.getDate()).padStart(2, "0");
  const hours = String(dateTime.getHours()).padStart(2, "0");
  const minutes = String(dateTime.getMinutes()).padStart(2, "0");
  const seconds = String(dateTime.getSeconds()).padStart(2, "0");

  const dayNames = ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"];
  const monthNames = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];

  const formattedDateTime = format
    .replaceAll("yyyy", year)
    .replaceAll("yy", String(year).slice(-2))
    .replaceAll("MMMM", monthNames[dateTime.getMonth()])
    .replaceAll("MMM", monthNames[dateTime.getMonth()].substr(0, 3))
    .replaceAll("MM", month)
    .replaceAll("ddd", dayNames[dateTime.getDay()])
    .replaceAll("dd", day)
    .replaceAll("hh", hours)
    .replaceAll("mm", minutes)
    .replaceAll("ss", seconds);

  return formattedDateTime;
};

// Obtiene un entero y lo devuelve formateado con el punto por cada 3 cifras
export const formatMoneyPunto = (number) => {
  number = parseFloat(number);
  const formattedNumber = number.toLocaleString("es-AR", { maximumFractionDigits: 2 });
  return formattedNumber;
};

//Obtiene el valor del softInputNumber y lo devuelve sin los puntos cada 3 cifras
//  y con el decimal separado un punto para la base de datos
export const separarEnLaComa = (value) => {
  let valor = value?.toString().split(",");
  let decimal = valor[1] ? valor[1] : "00";
  let entero = valor[0] ? valor[0] : "0";
  let total = parseFloat(entero + "." + decimal);
  return total;
};

export const removePeriods = (str) => {
  return parseFloat(str.replace(/\./g, "").replace(",", "."));
};

export function Money2Text(value) {
  let intPart = Math.trunc(value);
  let decPart = Math.round((value - intPart) * 100);
  let word = Num2Text(intPart) + " CON " + Num2Text(decPart) + " CENTAVOS";
  return word.toLowerCase();
}

function Num2Text(value) {
  switch (true) {
    case value === 0:
      return "CERO";
    case value === 1:
      return "UN";
    case value === 2:
      return "DOS";
    case value === 3:
      return "TRES";
    case value === 4:
      return "CUATRO";
    case value === 5:
      return "CINCO";
    case value === 6:
      return "SEIS";
    case value === 7:
      return "SIETE";
    case value === 8:
      return "OCHO";
    case value === 9:
      return "NUEVE";
    case value === 10:
      return "DIEZ";
    case value === 11:
      return "ONCE";
    case value === 12:
      return "DOCE";
    case value === 13:
      return "TRECE";
    case value === 14:
      return "CATORCE";
    case value === 15:
      return "QUINCE";
    case value < 20:
      return "DIECI" + Num2Text(value - 10);
    case value === 20:
      return "VEINTE";
    case value < 30:
      return "VEINTI" + Num2Text(value - 20);
    case value === 30:
      return "TREINTA";
    case value === 40:
      return "CUARENTA";
    case value === 50:
      return "CINCUENTA";
    case value === 60:
      return "SESENTA";
    case value === 70:
      return "SETENTA";
    case value === 80:
      return "OCHENTA";
    case value === 90:
      return "NOVENTA";
    case value < 100:
      return Num2Text(Math.trunc(value / 10) * 10) + " Y " + Num2Text(value % 10);
    case value === 100:
      return "CIEN";
    case value < 200:
      return "CIENTO " + Num2Text(value - 100);
    case [200, 300, 400, 600, 800].includes(value):
      return Num2Text(Math.trunc(value / 100)) + "CIENTOS";
    case value === 500:
      return "QUINIENTOS";
    case value === 700:
      return "SETECIENTOS";
    case value === 900:
      return "NOVECIENTOS";
    case value < 1000:
      return Num2Text(Math.trunc(value / 100) * 100) + " " + Num2Text(value % 100);
    case value === 1000:
      return "MIL";
    case value < 2000:
      return "MIL " + Num2Text(value % 1000);
    case value < 1000000:
      let numText = Num2Text(Math.trunc(value / 1000)) + " MIL";
      if (value % 1000) {
        numText += " " + Num2Text(value % 1000);
      }
      return numText;
    case value === 1000000:
      return "UN MILLON";
    case value < 2000000:
      return "UN MILLON " + Num2Text(value % 1000000);
    case value < 1000000000:
      return Num2Text(Math.trunc(value / 1000000)) + " MILLONES " + Num2Text(value % 1000000);
    case value < 2000000000:
      return "UN BILLON " + Num2Text(value % 1000000000);
    case value < 1000000000000.0:
      return Num2Text(Math.trunc(value / 1000000000)) + " BILLONES " + Num2Text(value % 1000000000);
    default:
      return "Número fuera de rango";
  }
}

export function pxToRem(px) {
  return px / 16 + "rem";
}

export function makeNiceText(text, type) {
  if (!text) return "";
  if (type === "nombre") {
    return text
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  } else {
    return text.toLowerCase().replace(/^\w/, (c) => c.toUpperCase());
  }
}

export function recortarTexto(texto, largo) {
  if (!texto) return "";
  if (texto.length > largo) {
    return texto.substring(0, largo) + "...";
  }
  return texto;
}

export function makePlural(text) {
  if (!text) return "";
  const lastChar = text.charAt(text.length - 1);
  const lastTwoChars = text.slice(-2);

  if (["a", "e", "i", "o", "u"].includes(lastChar)) {
    return text + "s";
  } else if (lastChar === "s") {
    return text;
  } else if (lastTwoChars === "z") {
    return text.slice(0, -1) + "ces";
  } else {
    return text + "es";
  }
}
