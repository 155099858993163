// React
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// @mui material components
import {
  Card,
  Fade,
  Grid,
  Icon,
  InputLabel,
  Modal,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { validateEmail } from "utils/validations";
import dataEstudios from "layouts/estudios/data/dataEstudios";
import Loading from "components/Carteles/Loading";
import DataTable from "components/Tables/DataTable";
import SinDatos from "components/Carteles/SinDatos";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "60%", xxl: "50%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalEstudioDetalles({ open, handleClose, estudio }) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between" mb={2}>
              <SoftBox>
                <SoftTypography variant="h6">{`Estudio Nº${estudio?.id}`}</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            {estudio?.tipoAnalisis === "Análisis Inicial" ? (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table sx={{ width: "100%" }} aria-label="simple table">
                      <TableRow>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="bold">
                            Ectoparásitos - Intensidad de Infestación
                          </SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="bold">
                            Pulgas
                          </SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="bold">
                            Nº
                          </SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="bold">
                            Garrapatas
                          </SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="bold">
                            Nº
                          </SoftTypography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular">
                            2 (Moderada)
                          </SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular">
                            6-20
                          </SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular">
                            12
                          </SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular">
                            4-10
                          </SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular">
                            8
                          </SoftTypography>
                        </TableCell>
                      </TableRow>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table sx={{ width: "100%" }} aria-label="simple table">
                      <TableRow>
                        <TableCell align="center">
                          <SoftTypography variant="button">Endoparásitos - Especies</SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button">Valor de HPG</SoftTypography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular">
                            Toxocara canis
                          </SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular">
                            200
                          </SoftTypography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular">
                            Toxascaris leonina
                          </SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular">
                            120
                          </SoftTypography>
                        </TableCell>
                      </TableRow>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table sx={{ width: "100%" }} aria-label="simple table">
                      <TableRow>
                        <TableCell align="center" colSpan={3}>
                          <SoftTypography variant="button">
                            Fecha de tratamiento 20/02/2024
                          </SoftTypography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">
                          <SoftTypography variant="button">Formulación recibida</SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button">Prueba</SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button">Referencia</SoftTypography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular">
                            FarmaTest 100mg (Lote 1234) (Vencimiento 20/02/2024)
                          </SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular">
                            1
                          </SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular">
                            1
                          </SoftTypography>
                        </TableCell>
                      </TableRow>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table sx={{ width: "100%" }} aria-label="simple table">
                      <TableRow>
                        <TableCell align="center">
                          <SoftTypography variant="button">Determinación</SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button">Fecha de inicio</SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button">Fecha de alta</SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button">Valores de referencia</SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button">Unidad</SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button">Observaciones</SoftTypography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">
                          <SoftTypography variant="button">Peso</SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular">
                            kg
                          </SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">
                          <SoftTypography variant="button">Mucosas tllc</SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular">
                            Seg.
                          </SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">
                          <SoftTypography variant="button">Linfonodulos</SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular">
                            -
                          </SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">
                          <SoftTypography variant="button">Temperatura</SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular">
                            ºC
                          </SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">
                          <SoftTypography variant="button">Sensorio/fascie</SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular">
                            -
                          </SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">
                          <SoftTypography variant="button">Frec. Respiratoria</SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular">
                            Resp/min
                          </SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">
                          <SoftTypography variant="button">Frec. Cardíaca</SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular">
                            Lat/min
                          </SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">
                          <SoftTypography variant="button">Hidratación</SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular">
                            Seg.
                          </SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">
                          <SoftTypography variant="button">Consumo (agua/alimento)</SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular">
                            -
                          </SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">
                          <SoftTypography variant="button">Celo/gestación</SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular">
                            Seg.
                          </SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular"></SoftTypography>
                        </TableCell>
                      </TableRow>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table sx={{ width: "100%" }} aria-label="simple table">
                      <TableRow>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="bold">
                            Fecha
                          </SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="bold">
                            Nº Pulgas
                          </SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="bold">
                            Nº Garrapatas
                          </SoftTypography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular">
                            24/02/2024
                          </SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular">
                            12
                          </SoftTypography>
                        </TableCell>
                        <TableCell align="center">
                          <SoftTypography variant="button" fontWeight="regular">
                            7
                          </SoftTypography>
                        </TableCell>
                        
                      </TableRow>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            )}
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalEstudioDetalles.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  estudio: PropTypes.object,
};
