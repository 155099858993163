// Components
import { Card, Pagination, Skeleton } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";

function CustomPagination({ loading, length, total, pages, page, setPage, noCard }) {
  return (
    <Card
      sx={
        noCard
          ? {
              borderRadius: 0,
              boxShadow: "none",
              borderTop: "0.0625rem solid #e9ecef",
            }
          : { mt: 2 }
      }
    >
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        textAlign="center"
        p={3}
        py={2}
      >
        {loading ? (
          <Skeleton variant="text" width={200} />
        ) : (
          <SoftBox>
            <SoftBox alignItems="center" textAlign="center" display={{ xs: "none", sm: "flex" }}>
              <SoftTypography variant="caption" color="dark">
                Mostrando{" "}
                <SoftTypography variant="caption" color="dark" fontWeight="bold">
                  {length || 0}
                </SoftTypography>{" "}
                de{" "}
                <SoftTypography variant="caption" color="dark" fontWeight="bold">
                  {total}
                </SoftTypography>{" "}
                registros
              </SoftTypography>
            </SoftBox>
            <SoftBox alignItems="center" textAlign="center" display={{ xs: "flex", sm: "none" }}>
              <SoftTypography variant="caption" color="dark">
                <SoftTypography variant="caption" color="dark" fontWeight="bold">
                  {length || 0}
                </SoftTypography>{" "}
                de{" "}
                <SoftTypography variant="caption" color="dark" fontWeight="bold">
                  {total}
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        )}
        {loading ? (
          <Skeleton variant="text" width={200} />
        ) : (
          <SoftBox>
            <Pagination
              count={pages}
              color="primary"
              page={page}
              onChange={(e, value) => {
                setPage(value);
              }}
            />
          </SoftBox>
        )}
      </SoftBox>
    </Card>
  );
}

CustomPagination.propTypes = {
  loading: PropTypes.bool,
  length: PropTypes.number,
  total: PropTypes.number,
  pages: PropTypes.number,
  page: PropTypes.number,
  setPage: PropTypes.func,
  noCard: PropTypes.bool,
};

export default CustomPagination;
