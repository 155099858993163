/* eslint-disable react/prop-types */

import { gql, useQuery, useSubscription } from "@apollo/client";
import { useContext, useEffect, useRef } from "react";
import { createContext, useState } from "react";
import { UserContext } from "./user";

const ChatContext = createContext();

const ChatProvider = ({ children }) => {
  //useRef for user id
  const { user } = useContext(UserContext);
  const empleadoID = useRef(user?.id || null);

  //chats
  const { data: activeChats, loading, error, refetch } = useQuery(gql`
    query CHATS($id: ID!) {
      consultasAsesor(id: $id chatOnly: true){
        idConsulta: id
        idChat
      }
    }
  `,{
    variables: { id: empleadoID.current },
    skip: !empleadoID.current,
  });

  //subscription
  const { data } = useSubscription(
    gql`
      subscription CHAT_SUBSCRIPTION($ids: [String!]!) {
        chats(
          ids: $ids
        ) {
          idChat
          notify
          name
          text
          createdAt
        }
      }
    `,
    {
      variables: { ids: activeChats?.consultasAsesor.map((chat) => chat.idChat) || [] },
      skip: loading || error || !empleadoID.current,
    }
  );

  if ("Notification" in window) {
    // Comprueba si el navegador admite notificaciones.
  
    Notification.requestPermission().then(function (permission) {
      if (permission === "granted") {
        // El usuario ha otorgado permiso para recibir notificaciones.
        // Ahora puedes mostrar notificaciones.
      } else if (permission === "denied") {
        // El usuario ha denegado el permiso para recibir notificaciones.
        // Debes respetar esta decisión y no mostrar notificaciones.
      }
    });
  }

  useEffect(() => {
    if(data){
      if(Notification.permission === "granted"){
        const notification = new Notification(
          "Nuevo mensaje de " + data.chats.name,
          {
            body: data.chats.text,
            icon: "/logo192.png"
          }
        )
      }

      if(data.chats.notify){
        refetch();
      }
    }
  }, [data]);

  return <ChatContext.Provider value={data}>{children}</ChatContext.Provider>;
};

export { ChatProvider, ChatContext };