

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Kaizen Dashboard base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import { Tooltip } from "@mui/material";

function DinamicCard({ children, title, data, actions }) {
  const labels = [];
  const values = [];

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(data).forEach((el) => {
    labels.push(el);
  });

  // Push the object values into the values array
  Object.values(data).forEach((el) => values.push(el));

  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <SoftBox key={label} mb={1} lineHeight={0}>
      <SoftTypography variant="caption" color="text" textTransform="capitalize">
        {label}:&nbsp;&nbsp;&nbsp;
        <SoftTypography variant="caption" fontWeight="medium">
          {values[key]}
        </SoftTypography>
      </SoftTypography>
    </SoftBox>
  ));

  return (
    <SoftBox
      /* component="li" */
      display="flex"
      justifyContent="space-between"
      alignItems="flex-start"
      bgColor="grey-100"
      borderRadius="lg"
      p={2}
      height="100%"
    /* mt={2} */
    >
      <SoftBox width="100%" display="flex" flexDirection="column">
        {title || actions?.length > 0 ? (
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
            mb={2}
          >
            {title && (
              <SoftTypography variant="button">
                {title}
              </SoftTypography>
            )}

            <SoftBox
              display="flex"
              alignItems="center"
            >
              {actions?.map(({ name, color, icon, action, tooltip }, index) => (
                <SoftBox key={index}>
                  <Tooltip title={tooltip || ""}>
                    <SoftButton variant="text" color={color} onClick={action}>
                      <Icon>{icon}</Icon>&nbsp;{name}
                    </SoftButton>
                  </Tooltip>
                </SoftBox>
              ))}
            </SoftBox>
          </SoftBox>
        ) : null}
        {renderItems}
        {children}
      </SoftBox>
    </SoftBox>
  );
}

// Setting default values for the props of Bill
DinamicCard.defaultProps = {
  title: "",
  data: {},
  actions: [],
  children: null,
};

// Typechecking props for the Bill
DinamicCard.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.string).isRequired,
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
  children: PropTypes.node,
};

export default DinamicCard;