/* eslint-disable react/prop-types */

import { Icon, Menu, MenuItem } from "@mui/material";
import SoftBox from "components/CheckBox";
import SoftTypography from "components/SoftTypography";
import { createContext, useState } from "react";

const MenuContext = createContext();

const MenuProvider = ({ children }) => {
  const [menuData, setMenuData] = useState({
    open: null,
    align: "left",
    options: [],
    handleClose: () => {},
  });

  const menu = ({ open, align, options }) => {
    setMenuData({
      open,
      align,
      options,
      handleClose: () => setMenuData((prev) => ({ ...prev, open: null })),
    });
  };

  const renderMenu = (
    <Menu
      anchorEl={menuData.open}
      open={Boolean(menuData.open)}
      onClose={menuData.handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: menuData.align,
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: menuData.align,
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          maxHeight: "300px",
          overflowY: "auto",
          overflowX: "hidden",
          mt: 4,
        },
      }}
    >
      {menuData.options
        .filter((option) => !option?.hidden)
        .map((option, index) => (
          <MenuItem onClick={option?.onClick || null} key={index} disabled={option?.disabled}>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                width: "100%",
              }}
            >
              <SoftBox mr={2}>
                <SoftTypography variant="p">{option?.name || "Sin nombre"}</SoftTypography>
              </SoftBox>
              {option?.icon?.icon && (
                <SoftBox>
                  {typeof option?.icon?.color === "func" ||
                    (typeof option?.icon?.color === "string" &&
                    option?.icon?.color.includes("#") ? (
                      <Icon
                        fontSize="small"
                        sx={{
                          verticalAlign: "middle",
                          color: option?.icon?.color,
                        }}
                      >
                        {option?.icon?.icon || "warning"}
                      </Icon>
                    ) : (
                      <Icon
                        fontSize="small"
                        color={option?.icon?.color}
                        sx={{
                          verticalAlign: "middle",
                        }}
                      >
                        {option?.icon?.icon || "warning"}
                      </Icon>
                    ))}
                </SoftBox>
              )}
            </SoftBox>
          </MenuItem>
        ))}
    </Menu>
  );

  return (
    <MenuContext.Provider value={{ menu, open: menuData.open }}>
      {renderMenu}
      {children}
    </MenuContext.Provider>
  );
};

export { MenuContext, MenuProvider };
