// React
import { useContext, useEffect, useState } from "react";

// @mui material components
import { Card, Grid, Pagination, Skeleton, Tooltip, Icon, Menu, MenuItem } from "@mui/material";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DataTable from "components/DataTable";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import dataTable from "layouts/soporte/data";
import ModalAdd from "./components/ModalAdd";
import ModalDetalle from "./components/ModalDetalle";
import { UserContext } from "context/user";

// API
import { getIncidentes } from "layouts/soporte/querys";
import SinDatos from "components/Carteles/SinDatos";

function ErrorLogs() {
  const { user } = useContext(UserContext);

  const [page, setPage] = useState(1);
  const [incidentes, setIncidentes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagesInfo, setPagesInfo] = useState({
    pages: 0,
    total: 0,
  });
  const [openNewIncidente, setOpenNewIncidente] = useState(false);
  const [openEditIncidente, setOpenEditIncidente] = useState(false);
  const [selectedIncidente, setSelectedIncidente] = useState({});

  const handleOpenNewIncidente = () => {
    setOpenNewIncidente(true);
  };

  const handleCloseNewIncidente = () => {
    setOpenNewIncidente(false);
  };

  const handleOpenEditIncidente = (incidente) => {
    setSelectedIncidente(incidente);
    setOpenEditIncidente(true);
  };

  const handleCloseEditIncidente = () => {
    setSelectedIncidente({});
    setOpenEditIncidente(false);
  };

  const { columns, getRows } = dataTable;

  useEffect(() => {
    getIncidentes(user.id).then((response) => {
      setIncidentes(response);
      setLoading(false);
    });
  }, [openNewIncidente]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Listado de consultas</SoftTypography>
              <SoftBox display="flex" justifyContent="end" alignItems="center">
                <SoftBox>
                  <Tooltip title="Agregar Incidente" placement="top">
                    <SoftButton color="primary" circular onClick={handleOpenNewIncidente}>
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>&nbsp; Agregar
                    </SoftButton>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {loading ? (
                <SoftBox p={3}>
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                </SoftBox>
              ) : incidentes?.length > 0 ? (
                <DataTable columns={columns} rows={getRows(incidentes, handleOpenEditIncidente)} />
              ) : (
                <SinDatos />
              )}
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
      <ModalAdd open={openNewIncidente} handleClose={handleCloseNewIncidente} user={user} />
      <ModalDetalle
        open={openEditIncidente}
        handleClose={handleCloseEditIncidente}
        user={user}
        incidente={selectedIncidente}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default ErrorLogs;
