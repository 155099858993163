import React, { useEffect, useState } from "react";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import { Card, Icon } from "@mui/material";

const InstallPWA = ({ setInstallOpen }) => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };
  if (!supportsPWA) {
    return null;
  }
  return (
    //Soft box centrado horizontalmente
    <SoftBox
      p={3}
      pb={2}
      sx={{
        position: "fixed",
        zIndex: 9999,
        width: "100%",
        bottom: 0,
      }}
    >
      <Card
        sx={{
          boxShadow: "0rem 1.25rem 1.6875rem 0rem rgba(0, 0, 0, 0.2)",
        }}
      >
        <SoftBox p={2} justifyContent="center" alignItems="center" textAlign="center">
          <SoftBox justifyContent="end" alignItems="end" textAlign="end">
            <SoftBox>
              <Icon
                onClick={() => {
                  sessionStorage.setItem("installPWA", "false");
                  setInstallOpen(false);
                }}
              >
                close
              </Icon>
            </SoftBox>
          </SoftBox>
          <SoftBox p={2}>
            <SoftBox mb={1}>
              <SoftTypography variant="h3" fontWeight="bold" color="primary" textGradient>
                UPRO APP
              </SoftTypography>
            </SoftBox>
            <SoftTypography variant="body2" fontWeight="regular" color="text">
              Instala ahora la aplicación para disfrutar de una mejor experiencia
            </SoftTypography>
          </SoftBox>
          <SoftBox p={2}>
            <SoftButton
              id="setup_button"
              onClick={onClick}
              color="primary"
              variant="gradient"
              fullWidth
            >
              Instalar&nbsp;
              <Icon>download</Icon>
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </Card>
    </SoftBox>
  );
};

InstallPWA.propTypes = {
  setInstallOpen: PropTypes.func,
};

export default InstallPWA;
