/* eslint-disable react/prop-types */

import ModalConfirm from "components/Modals/ConfirmMsg";
import { createContext, useState } from "react";

const ConfirmarContext = createContext();

const ConfirmarProvider = ({ children }) => {
  const [ confirmData, setConfirmData ] = useState({ open: false, handleClose: () => {}});

  const confirmar = ({ icon, title, message, func, pedirMotivo, placeholder }) => {
    setConfirmData({
      open: true,
      handleClose: () => setConfirmData(prev => ({ ...prev, open: false })),
      icon,
      title,
      message,
      func,
      pedirMotivo,
      placeholder
    });
  };

  return <ConfirmarContext.Provider value={{confirmar}}>
    <ModalConfirm
      {...confirmData}
    />
    {children}
  </ConfirmarContext.Provider>;
};

export { ConfirmarContext, ConfirmarProvider };
