// React
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// @mui material components
import { Card, Fade, Grid, Icon, InputLabel, Modal, Tooltip } from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { validateEmail } from "utils/validations";
import dataEstudios from "layouts/estudios/data/dataEstudios";
import Loading from "components/Carteles/Loading";
import DataTable from "components/Tables/DataTable";
import SinDatos from "components/Carteles/SinDatos";
import ModalEstudioDetalles from "../ModalEstudioDetalles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "60%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalEstudios({ open, handleClose }) {
  const [rows, setRows] = useState([]);
  const { columns, getRows } = dataEstudios;
  const [openDetallesModal, setOpenDetallesModal] = useState(false);
  const [selectedEstudio, setSelectedEstudio] = useState(null);
  const dataDemo = [
    {
      id: "1",
      sujeto: "Firulais",
      tipo: "Canino",
      tipoAnalisis: "Análisis Inicial",
      protocolo: "123456",
      estado: "Finalizado",
      fecha: "24-03-2024",
    },
    {
      id: "2",
      sujeto: "Firulais",
      tipo: "Canino",
      tipoAnalisis: "Análisis Periódico",
      protocolo: "123456",
      estado: "Finalizado",
      fecha: "24-04-2024",
    },
    {
      id: "3",
      sujeto: "Firulais",
      tipo: "Canino",
      tipoAnalisis: "Análisis Periódico",
      protocolo: "123456",
      estado: "Finalizado",
      fecha: "24-05-2024",
    },
    {
      id: "4",
      sujeto: "Firulais",
      tipo: "Canino",
      tipoAnalisis: "Análisis Periódico",
      protocolo: "123456",
      estado: "Finalizado",
      fecha: "24-06-2024",
    },
    {
      id: "5",
      sujeto: "Firulais",
      tipo: "Canino",
      tipoAnalisis: "Análisis Periódico",
      protocolo: "123456",
      estado: "Pendiente",
      fecha: "24-07-2024",
    },
  ];

  useEffect(() => {
    setRows(
      getRows(dataDemo, (item) => {
        setOpenDetallesModal(true);
        setSelectedEstudio(item);
      })
    );
  }, [open]);
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">Estudios</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox mb={2}>
              <Card>
                <SoftBox p={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                      <InputLabel htmlFor="nombreContacto">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Nombre del sujeto
                        </SoftTypography>
                        <SoftTypography variant="h6">Firulais</SoftTypography>
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <InputLabel htmlFor="nombreContacto">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Tipo
                        </SoftTypography>
                        <SoftTypography variant="h6">Canino</SoftTypography>
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <InputLabel htmlFor="nombreContacto">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Edad
                        </SoftTypography>
                        <SoftTypography variant="h6">5 años</SoftTypography>
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <InputLabel htmlFor="nombreContacto">
                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                          Sexo
                        </SoftTypography>
                        <SoftTypography variant="h6">Macho</SoftTypography>
                      </InputLabel>
                    </Grid>
                  </Grid>
                </SoftBox>
              </Card>
            </SoftBox>
            <Card>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {false ? (
                <Loading />
              ) : rows?.length > 0 ? (
                <DataTable columns={columns} rows={rows} />
              ) : (
                <SinDatos />
              )}
            </SoftBox>
            </Card>
            <ModalEstudioDetalles
              open={openDetallesModal}
              handleClose={() => setOpenDetallesModal(false)}
              estudio={selectedEstudio}
            />
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalEstudios.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};
