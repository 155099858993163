// @mui material components
import { Card, Fade, Icon, Modal, Tooltip } from "@mui/material";
import PropTypes from "prop-types";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "40%", xxl: "30%" },
  overflow: "auto",
  p: 3,
};

export default function ModalNotificacionVer({ open, handleClose, notificacion }) {
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Card sx={style}>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center">
              <SoftTypography variant="h6">Ver notificación</SoftTypography>
              <Tooltip title="Cerrar" placement="top">
                <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                  close
                </Icon>
              </Tooltip>
            </SoftBox>
            <SoftBox>
              <SoftBox bgColor={"grey-100"} borderRadius="lg" p={2} mt={2}>
                <SoftBox display="flex" justifyContent="space-between">
                  <SoftBox display="flex" alignItems="center" gap={1}>
                    <Icon fontSize="medium">notifications</Icon>
                    <SoftTypography variant="h6">{notificacion?.header}</SoftTypography>
                  </SoftBox>
                  <SoftBox display="flex">
                    <SoftTypography variant="caption">
                      {notificacion?.programmedAt
                        ? dayjs(notificacion?.programmedAt).format("DD/MM/YYYY HH:mm")
                        : notificacion?.createdAt
                        ? dayjs(notificacion?.createdAt).format("DD/MM/YYYY HH:mm")
                        : "Sin fecha"}
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
                <SoftBox ml={4}>
                  <SoftTypography variant="body2">
                    {notificacion?.body || "Sin descripción"}
                  </SoftTypography>
                </SoftBox>
                {notificacion?.url && (
                  <SoftBox display="flex" justifyContent="flex-end">
                    <Link to={notificacion?.url}>
                    <SoftButton variant="text" color="dark" onClick={() => setOpenComentarios(true)}>
                      Ir al link&nbsp;
                      <Icon>arrow_forward</Icon>
                    </SoftButton>
                    </Link>
                  </SoftBox>
                )}
              </SoftBox>
            </SoftBox>
          </Card>
        </Fade>
      </Modal>
    </>
  );
}

ModalNotificacionVer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  notificacion: PropTypes.object.isRequired,
};
