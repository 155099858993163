// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// uuid is a library for generating unique id

// @mui material components

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Kaizen Dashboard base styles
import { makeNiceText } from "utils/formatters";

function DataTable({ icono, titulo, codigo, descripcion, nombreDescripcion, funcion }) {
  return (
    <SoftBox
      borderRadius="lg"
      px={3}
      py={1}
      sx={{
        cursor: "pointer",
      }}
      onClick={funcion}
    >
      <SoftBox display="flex" justifyContent="space-between" alignItems="center">
        <SoftBox display="flex" alignItems="center">
          <SoftBox mr={1} display="flex">
            {icono}
          </SoftBox>
          <SoftBox display="flex-row" alignItems="center">
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h7" fontWeight="bold" >
                {titulo}
              </SoftTypography>
            </SoftBox>
            <SoftBox mt={-1}>
              <SoftTypography variant="caption">
                {descripcion || `Sin ${makeNiceText(nombreDescripcion)}`}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <SoftBox display="flex" alignItems="center" ml={2}>
          <SoftTypography variant="caption">{codigo}</SoftTypography>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  funcion: () => {},
  nombreDescripcion: "descripción",
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  icono: PropTypes.node.isRequired,
  titulo: PropTypes.string.isRequired,
  codigo: PropTypes.string.isRequired,
  descripcion: PropTypes.string,
  nombreDescripcion: PropTypes.string,
  funcion: PropTypes.func,
};

export default DataTable;
