import { Grid } from "@mui/material";
import Logo from "assets/images/logo.png";
import boxShadows from "assets/theme/base/boxShadows";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import dayjs from "dayjs";
import { jsPDF } from "jspdf";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { formatMoneyPunto } from "utils/formatters";

const PresupuestoPDF = ({ proyecto, setFile }) => {
  const documentsRef = useRef(null);
  const { lg } = boxShadows;

  const handleGeneratePdf = async () => {
    const document = documentsRef.current.childNodes[0];

    const doc = new jsPDF({
      format: "a4",
      unit: "px",
      orientation: "portrait",
      hotfixes: ["px_scaling"],
    });

    await doc.html(document, {
      callback(doc) {
        const pageCount = doc.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          const pageSize = doc.internal.pageSize;
          const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          const header = "Presupuesto NºX-Y";
          const brand = "Kaizen Software";
          const footer = `© ${dayjs().year()} Powered by Kaizen Software Factory`;
          const date = dayjs().format("DD/MM/YYYY");

          // Header
          doc.setFontSize(10);

          if (i !== 1) {
            doc.text(header, 40, 15, { baseline: "top" });
            doc.text(brand, pageWidth - 40 - doc.getTextWidth(brand), 15, { baseline: "top" });
          }

          // Footer
          doc.text(footer, 40, pageHeight - 15, { baseline: "bottom" });
          doc.text(date, pageWidth - 40 - doc.getTextWidth(date), pageHeight - 15, {
            baseline: "bottom",
          });
        }
        setFile(doc.output("blob"));
      },
    });
  };

  useEffect(() => {
    if (proyecto) {
      handleGeneratePdf();
    }
  }, [proyecto]);

  return (
    <SoftBox
      p={2}
      display="flex"
      alignItems="center"
      flexDirection="column"
      minHeight="100vh"
      height="100%"
    >
      <div ref={documentsRef}>
        <SoftBox
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          sx={{
            position: "relative",
            overflow: "hidden",
            width: "794px",
            height: "1110px",
            backgroundColor: "#ffffff",
            boxShadow: lg,
            px: 6,
            pt: 3,
          }}
        >
          <SoftBox
            sx={{
              flexGrow: 1,
            }}
          >
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
              <img src={Logo} alt="Terra" width="120px" />
              <SoftBox display="flex" flexDirection="column" gap={1} alignItems="end">
                <SoftTypography variant="caption">Kaizen Software</SoftTypography>
                <SoftTypography variant="caption">Presupuesto NºX-Y</SoftTypography>
              </SoftBox>
            </SoftBox>

            <SoftBox>
              <SoftBox mb={3}>
                <SoftTypography variant="h6" fontWeight="bold" textAlign="center">
                  Cotización
                </SoftTypography>
                <SoftTypography variant="h6" textAlign="center">
                  {`Proyecto: ${proyecto?.nombre}`}
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={3}>
                <SoftTypography variant="h6" fontWeight="bold">
                  Detalles del presupuesto
                </SoftTypography>
                <Grid container>
                  <Grid item xs={6}>
                    <SoftTypography variant="caption" fontWeight="bold">
                      Nombre del proyecto:&nbsp;
                      <SoftTypography variant="caption">{proyecto?.nombre}</SoftTypography>
                    </SoftTypography>
                  </Grid>
                  {proyecto?.responsable && (
                    <Grid item xs={6}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Responsable:&nbsp;
                        <SoftTypography variant="caption">{`${proyecto?.responsable?.nombre} ${proyecto?.responsable?.apellido}`}</SoftTypography>
                      </SoftTypography>
                    </Grid>
                  )}
                  {proyecto?.direccion && (
                    <Grid item xs={6}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Dirección:&nbsp;
                        <SoftTypography variant="caption">{`${proyecto?.direccion}, ${proyecto?.provincia?.nombre}, ${proyecto?.localidad?.nombre}`}</SoftTypography>
                      </SoftTypography>
                    </Grid>
                  )}
                  {proyecto?.veterinaria && (
                    <Grid item xs={6}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Veterinaria:&nbsp;
                        <SoftTypography variant="caption">{`${proyecto?.veterinaria?.nombre} ${proyecto?.veterinaria?.apellido}`}</SoftTypography>
                      </SoftTypography>
                    </Grid>
                  )}
                  {proyecto?.vendedor && (
                    <Grid item xs={6}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Vendedor:&nbsp;
                        <SoftTypography variant="caption">{`${proyecto?.vendedor?.nombre} ${proyecto?.vendedor?.apellido}`}</SoftTypography>
                      </SoftTypography>
                    </Grid>
                  )}
                  {proyecto?.nroContrato && (
                    <Grid item xs={6}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Nº Contrato:&nbsp;
                        <SoftTypography variant="caption">{proyecto?.nroContrato}</SoftTypography>
                      </SoftTypography>
                    </Grid>
                  )}
                  {proyecto?.fechaInicioEstimada && (
                    <Grid item xs={6}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Fecha de inicio:&nbsp;
                        <SoftTypography variant="caption">
                          {proyecto?.fechaInicioEstimada}
                        </SoftTypography>
                      </SoftTypography>
                    </Grid>
                  )}
                  {proyecto?.fechaFinEstimada && (
                    <Grid item xs={6}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Fecha de fin:&nbsp;
                        <SoftTypography variant="caption">
                          {proyecto?.fechaFinEstimada}
                        </SoftTypography>
                      </SoftTypography>
                    </Grid>
                  )}
                  {proyecto?.terminosPago && (
                    <Grid item xs={6}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Términos de pago:&nbsp;
                        <SoftTypography variant="caption">{proyecto?.terminosPago}</SoftTypography>
                      </SoftTypography>
                    </Grid>
                  )}
                  {proyecto?.importeDeposito && (
                    <Grid item xs={6}>
                      <SoftTypography variant="caption" fontWeight="bold">
                        Depósito:&nbsp;
                        <SoftTypography variant="caption">
                          ${formatMoneyPunto(proyecto?.importeDeposito)}
                        </SoftTypography>
                      </SoftTypography>
                    </Grid>
                  )}
                  {proyecto?.notasExtra && (
                    <Grid item xs={12}>
                      <SoftTypography variant="caption" fontWeight="text">
                        {proyecto?.notasExtra}
                      </SoftTypography>
                    </Grid>
                  )}
                </Grid>
              </SoftBox>
              <SoftBox>
                <SoftTypography variant="h6" fontWeight="bold">
                  Fases del proyecto
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </div>
    </SoftBox>
  );
};

PresupuestoPDF.propTypes = {
  proyecto: PropTypes.object,
  setFile: PropTypes.func,
};

export default PresupuestoPDF;
