function sentStyle(theme) {
    const { palette, borders, transitions } = theme;
  
    const { secondary, light } = palette;
    const { borderRadius } = borders;
  
    return {
      display: "flex",
      alignItems: "center",
      width: "99%",
      color: secondary.main,
      justifyContent: "end",
      paddingTop: 2,
      paddingBottom: 2,
      borderRadius: "20px",
      borderBottomRightRadius: 0,
      backgroundColor: "#f8f9fa",
      transition: transitions.create("background-color", {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
  
      "&:not(:last-child)": {
        mb: 1.25,
      },
  
      "&:hover": {
        backgroundColor: light.main,
      },
    };
  }
  
  function menuItem(theme) {
    const { palette, borders, transitions } = theme;
  
    const { secondary, light } = palette;
    const { borderRadius } = borders;
  
    return {
      display: "flex",
      alignItems: "center",
      width: "99%",
      color: secondary.main,
      justifyContent: "start",
      paddingTop: 2,
      paddingBottom: 2,
      borderRadius: "20px",
      borderTopLeftRadius: 0,
      backgroundColor: "#f8f9fa",
      transition: transitions.create("background-color", {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
  
      "&:not(:last-child)": {
        mb: 1.25,
      },
  
      "&:hover": {
        backgroundColor: light.main,
      },
    };
  }
  
  function menuImage(theme, ownerState) {
    const { functions, palette, borders } = theme;
    const { color } = ownerState;
  
    const { linearGradient } = functions;
    const { gradients } = palette;
    const { borderRadius } = borders;
  
    return {
      display: "grid",
      placeItems: "center",
      backgroundImage: gradients[color]
        ? linearGradient(gradients[color].main, gradients[color].state)
        : "none",
      backgroundColor: color ? gradients[color].main : "#fff",
  
      "& img": {
        width: "100%",
        borderRadius: borderRadius.lg,
      },
      marginRight: "0",
    };
  }
  
  export { menuItem, menuImage, sentStyle };
  