// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import MiniStatisticsCard from "components/Cards/StatisticsCards/MiniStatisticsCard";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftInputNumber from "components/SoftInputNumberUpgrade";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import dayjs from "dayjs";
import { MenuContext } from "context/menuContext";
import Fases from "../Fases";
import EstudioDetalles from "../EstudioDetalles";
import Cotizacion from "../Cotizacion";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "60%", xxl: "50%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalProyecto({ open, handleClose, refetch, selectedProyecto, tipo }) {
  const [proyecto, setProyecto] = useState();
  const [proyectoOld, setProyectoOld] = useState();
  const { handleSnackbar } = useContext(MessageManager);

  const [saveProyecto, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveProyecto($input: ProyectoInput!) {
        saveProyecto(input: $input) {
          id
        }
      }
    `
  );

  const handleSave = () => {
    saveProyecto({
      variables: {
        input: {
          ...proyecto,
        },
      },
    })
      .then(() => {
        proyecto.id
          ? handleSnackbar("Proyecto actualizado correctamente", "success")
          : handleSnackbar("Proyecto creado correctamente", "success");
        refetch();
        handleClose();
      })
      .catch((e) => {
        handleSnackbar("Error al guardar el proyecto", "error");
      });
  };

  const hayCambios = (oldData, newData) => {
    let cambios = false;
    if (oldData && newData) {
      Object.keys(oldData).forEach((key) => {
        if (oldData[key] !== newData[key]) {
          cambios = true;
        }
      });
      Object.keys(newData).forEach((key) => {
        if (oldData.hasOwnProperty(key) && !newData.hasOwnProperty(key)) {
          cambios = true;
        }
      });
    }
    return cambios;
  };

  useEffect(() => {
    setProyecto();
    if (open && selectedProyecto) {
      let proyectoData = {
        ...selectedProyecto,
        idveterinaria: selectedProyecto?.veterinaria?.id,
        idResponsable: selectedProyecto?.responsable?.id,
        idVendedor: selectedProyecto?.vendedor?.id,
        idProvincia: selectedProyecto?.localidad?.provincia?.id,
        idLocalidad: selectedProyecto?.localidad?.id,
      };
      delete proyectoData.veterinaria;
      delete proyectoData.responsable;
      delete proyectoData.vendedor;
      delete proyectoData.localidad;
      delete proyectoData.__typename;
      Object.keys(proyectoData).forEach(
        (key) => proyectoData[key] == null && delete proyectoData[key]
      );
      setProyectoOld(proyectoData);
      setProyecto(proyectoData);
    }
  }, [open, selectedProyecto]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">
                  {`${selectedProyecto ? selectedProyecto.nombre : "Nuevo proyecto"} - ${
                    tipo === "Detalles" ? "Datos generales" : "Fases"
                  }`}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox py={3}>
              {tipo === "Detalles" && <EstudioDetalles proyecto={proyecto} setProyecto={setProyecto} />}
              {tipo === "Fases" && <Fases proyecto={proyecto} setProyecto={setProyecto} />}
              {tipo === "Cotizacion" && <Cotizacion proyecto={proyecto} setProyecto={setProyecto} />}
            </SoftBox>
            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="end"
              alignItems="center"
            >
              <SoftBox
                mr={{
                  xs: 0,
                  sm: 2,
                }}
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="primary"
                  circular
                  onClick={handleClose}
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                >
                  <Icon sx={{ fontWeight: "light" }}>close</Icon>
                  &nbsp;Cancelar
                </SoftButton>
              </SoftBox>
              <SoftBox
                mt={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <SoftButton
                  color="kaizenPrimary"
                  circular
                  fullWidth={{
                    xs: true,
                    sm: false,
                  }}
                  onClick={null}
                  disabled={
                    (proyectoOld && !hayCambios(proyectoOld, proyecto)) ||
                    loadingSave ||
                    !proyecto?.nombre ||
                    !proyecto?.idveterinaria ||
                    (proyecto?.fechaFinEstimada &&
                      proyecto?.fechaInicioEstimada &&
                      dayjs(proyecto?.fechaInicioEstimada).isAfter(
                        dayjs(proyecto?.fechaFinEstimada)
                      )) ||
                    (proyecto?.fechaFinEstimada && !proyecto?.fechaInicioEstimada)
                  }
                >
                  {loadingSave ? (
                    <CircularProgress size={15} color="inherit" />
                  ) : (
                    <Icon sx={{ fontWeight: "light" }}>
                      save
                    </Icon>
                  )}
                  &nbsp;Guardar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalProyecto.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  refetch: PropTypes.func,
  selectedProyecto: PropTypes.object,
  tipo: PropTypes.string,
};
