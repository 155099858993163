import { Skeleton } from "@mui/material";
import SoftBox from "components/SoftBox";
import PropTypes from "prop-types";

function Loading({rows}) {
  return (
    <SoftBox p={3}>
      {Array.from(Array(rows).keys()).map((row, index) => (
        <Skeleton key={index} variant="text" sx={{ fontSize: "1rem" }} />
      ))}
    </SoftBox>
  );
}

Loading.defaultProps = {
  rows: 2,
}

Loading.propTypes = {
  rows: PropTypes.number,
};

export default Loading;
