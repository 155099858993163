// React
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";

// @mui material components
import {
  Autocomplete,
  Card,
  CircularProgress,
  Fade,
  Grid,
  Icon,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import SinDatos from "components/Carteles/SinDatos";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { ConfirmarContext } from "context/ConfirmarContext";
import { getKaizenColor } from "utils/colors";
import { validateEmail } from "utils/validations";
// import ModalAddPlantilla from "../ModalAddPlantilla";
import { UserContext } from "context/user";
import { validateDNI } from "utils/validations";
import { validateCUIL } from "utils/validations";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "60%", xxl: "50%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalLaboratorios({ open, handleClose, refetch, selectedUsuario }) {
  const [laboratorio, setLaboratorio] = useState();
  const { handleSnackbar } = useContext(MessageManager);
  const [edit, setEdit] = useState(false);

  const handleChanges = (e) => {
    setLaboratorio({
      ...laboratorio,
      [e.target.name]: e.target.value,
    });
  };

  const { data: laboratorios } = useQuery(
    gql`
      query getLaboratorios {
        laboratorios {
          id
        }
      }
    `
  );

  const [saveLaboratorio, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveLaboratorio($input: LaboratorioInput!) {
        saveLaboratorio(input: $input) {
          id
        }
      }
    `
  );


  const { data: provincias } = useQuery(gql`
    query GetProvincias {
      provincias {
        id
        nombre
      }
    }
  `);

  const [getLocalidades, { data: localidades }] = useLazyQuery(gql`
    query GetLocalidades($provincia: ID!) {
      localidades(provincia: $provincia) {
        id
        nombre
        provincia {
          id
        }
      }
    }
  `);

  useEffect(() => {
    if (laboratorio?.provincia) {
      getLocalidades({ variables: { provincia: laboratorio?.provincia } }).then((data) => {
        // Si la localidad no pertenece a la provincia seleccionada, se limpia el campo
        if (
          !data?.data?.localidades?.find((localidad) => localidad.id === laboratorio?.localidad)
        ) {
          setLaboratorio({
            ...laboratorio,
            localidad: -1,
          });
        }
      });
    }
  }, [laboratorio?.provincia]);

  const handleSave = () => {
    saveLaboratorio({
      variables: {
        input: {
          id: laboratorio.id || null,
          nombre: laboratorio?.nombre,
          nombreResponsable: laboratorio.nombreResponsable,
          apellidoResponsable: laboratorio.apellidoResponsable,
          tipoDocumento: laboratorio.tipoDocumento,
          documento: laboratorio.documento,
          documentoResponsable: laboratorio.documentoResponsable,
          domicilio: laboratorio.domicilio,
          telefono: laboratorio.telefono,
          correo: laboratorio.correo,
          idProvincia: laboratorio.provincia,
          idLocalidad: laboratorio.localidad,
        },
      },
    })
      .then(() => {
        refetch();
        if (edit) {
          handleSnackbar("Laboratorio editado correctamente", "success");
        } else {
          handleSnackbar("Laboratorio guardado correctamente", "success");
        }
        handleClose();
      })
      .catch((e) => {
        handleSnackbar("Error al guardar el laboratorio", "error");
      });
  };

  useEffect(() => {
    if (selectedUsuario) {
      setEdit(true);
      setLaboratorio({
        id: selectedUsuario.id,
        nombre: selectedUsuario.nombre,
        nombreResponsable: selectedUsuario.nombreResponsable,
        apellidoResponsable: selectedUsuario.apellidoResponsable,
        tipoDocumento: selectedUsuario.tipoDocumento,
        documento: selectedUsuario.documento,
        documentoResponsable: selectedUsuario.documentoResponsable,
        domicilio: selectedUsuario.domicilio,
        telefono: selectedUsuario.telefono,
        correo: selectedUsuario.correo,
        provincia: selectedUsuario.localidad?.provincia?.id,
        localidad: selectedUsuario.localidad?.id,
      });
    } else {
      setEdit(false);
      setLaboratorio();
    }
  }, [selectedUsuario, open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">Laboratorio</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>

            <SoftBox pb={3} pt={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="nombre">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Nombre
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="nombre"
                    placeholder="Ingrese el nombre de la laboratorio"
                    type="text"
                    name="nombre"
                    value={laboratorio?.nombre || ""}
                    error={laboratorio?.nombre?.length > 0 && laboratorio?.nombre.trim() == ""}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <InputLabel htmlFor="tipoDocumento">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Tipo de documento
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="tipoDocumento"
                    value={laboratorio?.tipoDocumento || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione uno
                    </MenuItem>
                    <MenuItem value={96}>DNI</MenuItem>
                    <MenuItem value={80}>CUIT</MenuItem>
                    <MenuItem value={86}>CUIL</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <InputLabel htmlFor="documento">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Documento
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="documento"
                    placeholder="Ingrese el documento"
                    type="text"
                    name="documento"
                    value={laboratorio?.documento || ""}
                    error={
                      laboratorio?.tipoDocumento === 96 && laboratorio?.documento?.length > 0
                        ? !validateDNI(laboratorio?.documento)
                        : laboratorio?.documento?.length > 0 &&
                          !validateCUIL(laboratorio?.documento)
                    }
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="telefono">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Telefono
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="telefono"
                    placeholder="Ingrese el telefono de la laboratorio"
                    type="text"
                    name="telefono"
                    value={laboratorio?.telefono || ""}
                    error={laboratorio?.telefono?.length > 0 && laboratorio?.telefono.trim() == ""}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="nombreResponsable">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Nombre del responsable
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="nombreResponsable"
                    placeholder="Ingrese el nombre del responsable"
                    type="text"
                    name="nombreResponsable"
                    value={laboratorio?.nombreResponsable || ""}
                    error={
                      laboratorio?.nombreResponsable?.length > 0 &&
                      laboratorio?.nombreResponsable.trim() == ""
                    }
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="apellidoResponsable">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Apellido del responsable
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="apellidoResponsable"
                    placeholder="Ingrese el apellido del responsable"
                    type="text"
                    name="apellidoResponsable"
                    value={laboratorio?.apellidoResponsable || ""}
                    error={
                      laboratorio?.apellidoResponsable?.length > 0 &&
                      laboratorio?.apellidoResponsable.trim() == ""
                    }
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="documentoResponsable">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      DNI del responsable
                      <SoftTypography variant="caption" fontWeight="bold" color="primary">
                        &nbsp;*
                      </SoftTypography>
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="documentoResponsable"
                    placeholder="Ingrese el DNI del responsable"
                    type="text"
                    name="documentoResponsable"
                    value={laboratorio?.documentoResponsable || ""}
                    error={
                      laboratorio?.documentoResponsable?.length > 0 &&
                      !validateDNI(laboratorio?.documentoResponsable)
                    }
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="username">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Email
                    </SoftTypography>
                  </InputLabel>
                  <Tooltip
                    placement="bottom"
                    open={laboratorio?.correo?.length > 0 && !validateEmail(laboratorio?.correo)}
                    title={
                      laboratorio?.correo?.length > 0 && !validateEmail(laboratorio?.correo)
                        ? "Ingrese un email válido"
                        : "Ingrese un email"
                    }
                  >
                    <SoftInput
                      label="correo"
                      placeholder="Ingrese el email de la laboratorio"
                      type="text"
                      name="correo"
                      value={laboratorio?.correo || ""}
                      error={
                        laboratorio?.correo?.length > 0 &&
                        (!validateEmail(laboratorio?.correo) ||
                          laboratorios?.laboratorios.find((u) => u.correo === laboratorio?.correo))
                      }
                      onChange={handleChanges}
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="domicilio">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Domicilio
                    </SoftTypography>
                  </InputLabel>
                  <SoftInput
                    label="domicilio"
                    placeholder="Ingrese el domicilio de la laboratorio"
                    type="text"
                    name="domicilio"
                    value={laboratorio?.domicilio || ""}
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="provincia">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Provincia
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="provincia"
                    value={laboratorio?.provincia || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione uno
                    </MenuItem>
                    {provincias?.provincias?.map((provincia) => (
                      <MenuItem key={provincia.id} value={provincia.id}>
                        {provincia.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="localidad">
                    <SoftTypography component="label" variant="caption" fontWeight="bold">
                      Localidad
                    </SoftTypography>
                  </InputLabel>
                  <Select
                    name="localidad"
                    value={laboratorio?.localidad || -1}
                    onChange={handleChanges}
                  >
                    <MenuItem value={-1} disabled>
                      Seleccione uno
                    </MenuItem>
                    {localidades?.localidades?.map((localidad) => (
                      <MenuItem key={localidad.id} value={localidad.id}>
                        {localidad.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </SoftBox>
            <SoftBox
              display={{
                xs: "flex-row",
                sm: "flex",
              }}
              justifyContent="flex-end"
              alignItems="center"
            >
              <SoftBox
                display={{
                  xs: "flex-row",
                  sm: "flex",
                }}
                justifyContent="end"
                alignItems="center"
              >
                <SoftBox
                  mr={{
                    xs: 0,
                    sm: 2,
                  }}
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <SoftButton
                    color="primary"
                    circular
                    onClick={handleClose}
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                  >
                    <Icon sx={{ fontWeight: "light" }}>cancel</Icon>
                    &nbsp;Cancelar
                  </SoftButton>
                </SoftBox>
                <SoftBox
                  mt={{
                    xs: 2,
                    sm: 0,
                  }}
                >
                  <SoftButton
                    color="kaizenPrimary"
                    circular
                    disabled={
                      loadingSave ||
                      !laboratorio?.nombre ||
                      !laboratorio?.nombreResponsable ||
                      !laboratorio?.apellidoResponsable ||
                      !laboratorio?.tipoDocumento ||
                      !laboratorio?.documento ||
                      !laboratorio?.documentoResponsable ||
                      (laboratorio?.correo?.length > 0 && !validateEmail(laboratorio?.correo)) ||
                      (laboratorio?.documento?.length > 0 &&
                        laboratorio?.tipoDocumento === 96 &&
                        !validateDNI(laboratorio?.documento)) ||
                      (laboratorio?.documento?.length > 0 &&
                        laboratorio?.tipoDocumento === 80 &&
                        !validateCUIL(laboratorio?.documento)) ||
                      (laboratorio?.documentoResponsable?.length > 0 &&
                        !validateDNI(laboratorio?.documentoResponsable)) ||
                      (laboratorio?.telefono?.length > 0 && laboratorio?.telefono.trim() == "")
                    }
                    onClick={handleSave}
                    fullWidth={{
                      xs: true,
                      sm: false,
                    }}
                  >
                    {loadingSave ? (
                      <CircularProgress size={15} color="inherit" />
                    ) : (
                      <Icon sx={{ fontWeight: "light" }}>save</Icon>
                    )}
                    &nbsp;Guardar
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalLaboratorios.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  refetch: PropTypes.func,
  selectedUsuario: PropTypes.object,
};
