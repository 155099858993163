/* eslint-disable react/prop-types */

import { createContext, useState } from "react";

const UiContext = createContext();

const UiProvider = ({ children }) => {
  const [hideNavbar, setHideNavbar] = useState(false);

  const handleHideNavbar = (status) => {
    setHideNavbar(status);
  };

  const data = { hideNavbar, handleHideNavbar };

  return <UiContext.Provider value={data}>{children}</UiContext.Provider>;
};

export { UiContext, UiProvider };
