import { Grid, Icon } from "@mui/material";
import SoftTypography from "components/SoftTypography";

function SinDatos() {
  return (
    <Grid container textAlign={"center"} py={3}>
      <Grid item xs={12}>
        <Icon
          sx={{
            width: "50px",
          }}
        >
          folder_open
        </Icon>
      </Grid>
      <Grid item xs={12}>
        <SoftTypography variant="h6">No hay datos</SoftTypography>
      </Grid>
      <Grid item xs={12}>
        <SoftTypography variant="caption" component="p" color="text">
          No hay datos para mostrar
        </SoftTypography>
      </Grid>
    </Grid>
  );
}
export default SinDatos;
