// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { CircularProgress, Tooltip } from "@mui/material";
import SoftCircularProgress from "components/SoftCircularProgress";
import SoftProgress from "components/SoftProgress";
import SoftBadge from "components/SoftBadge";

function EstudioItem({ estudio, handleVeterinaria, handleLaboratorio, handleEstudios, handleEdit, handleAsignarVeterinaria }) {
  return (
    <Card>
      <SoftBox p={2}>
        <SoftBox display="flex" alignItems="center" justifyContent="space-between">
          <SoftBox display="flex">
            <SoftBox lineHeight={1}>
              <SoftBox display="flex" alignItems="center">
                <SoftBox display="flex" alignItems="center" mr={1}>
                  <SoftTypography variant="button">{`Estudio Nº ${estudio?.id}`}</SoftTypography>
                </SoftBox>
                <SoftBox>
                  <SoftBadge
                    badgeContent={estudio?.estado}
                    color={
                      estudio?.estado === "Pendiente de asignación"
                        ? "error"
                        : estudio?.estado === "En progreso"
                        ? "info"
                        : "success"
                    }
                  />
                </SoftBox>
              </SoftBox>
              <SoftBox display="flex" alignItems="center" mt={1}>
                <SoftTypography variant="h5" fontWeight="bold" color={"primary"}>
                  {estudio.nombre}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" alignItems="center" mt={1}>
              <SoftBox display="flex" alignItems="center" mr={2}>
                  <SoftBox mr={1}>
                    <Icon color="primary" fontSize="small">
                      person
                    </Icon>
                  </SoftBox>
                  <SoftTypography variant="button">{`${estudio.responsable.nombre}`}</SoftTypography>
                </SoftBox>
                <SoftBox display="flex" alignItems="center" mr={2}>
                  <SoftBox mr={1}>
                    <Icon color="primary" fontSize="small">
                      biotech
                    </Icon>
                  </SoftBox>
                  <SoftTypography variant="button">{`${estudio.laboratorio.nombre}`}</SoftTypography>
                </SoftBox>
                <SoftBox display="flex" alignItems="center" mr={2}>
                  <SoftBox mr={1}>
                    <Icon
                      color={estudio?.veterinaria?.nombre ? "primary" : "secondary"}
                      fontSize="small"
                    >
                      pets
                    </Icon>
                  </SoftBox>
                  <SoftTypography variant="button">{`${
                    estudio?.veterinaria?.nombre || "Sin asignar"
                  }`}</SoftTypography>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <SoftBox display="flex">
            {estudio?.estado !== "Pendiente de asignación" && (
              <SoftBox mr={2}>
                <Tooltip placement="top" title="Ver laboratorio">
                  <SoftButton circular iconOnly color="kaizenPrimary" onClick={() => handleLaboratorio(estudio)}>
                    <Icon>biotech</Icon>
                  </SoftButton>
                </Tooltip>
              </SoftBox>
            )}
            {estudio?.estado !== "Pendiente de asignación" && (
              <SoftBox mr={2}>
                <Tooltip placement="top" title="Ver veterinaria">
                  <SoftButton circular iconOnly color="kaizenPrimary" onClick={() => handleVeterinaria(estudio)}>
                    <Icon>pets</Icon>
                  </SoftButton>
                </Tooltip>
              </SoftBox>
            )}
            {estudio?.estado !== "Pendiente de asignación" && (
              <SoftBox mr={2}>
                <Tooltip placement="top" title="Ver estudios" onClick={() => handleEstudios(estudio)}>
                  <SoftButton circular iconOnly color="kaizenPrimary">
                    <Icon>science</Icon>
                  </SoftButton>
                </Tooltip>
              </SoftBox>
            )}
            {estudio?.estado === "Pendiente de asignación" && (
              <SoftBox mr={2}>
                <Tooltip placement="top" title="Asignar una veterinaria al estudio">
                  <SoftButton circular color="kaizenPrimary" onClick={() => handleAsignarVeterinaria(estudio)}>
                    Asignar veterinaria&nbsp;
                    <Icon>arrow_forward</Icon>
                  </SoftButton>
                </Tooltip>
              </SoftBox>
            )}

            <SoftBox>
              <Tooltip placement="top" title="Editar">
                <SoftButton
                  circular
                  iconOnly
                  color="primary"
                >
                  <Icon>edit</Icon>
                </SoftButton>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

EstudioItem.propTypes = {
  estudio: PropTypes.object.isRequired,
  handleVeterinaria: PropTypes.func.isRequired,
  handleLaboratorio: PropTypes.func.isRequired,
  handleEstudios: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleAsignarVeterinaria: PropTypes.func.isRequired,
};

export default EstudioItem;
