import { Backdrop, Card, Fade, Grid, Icon, Input, InputLabel, Modal, Tooltip } from "@mui/material";
import PropTypes from "prop-types";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: "70%", md: "30%" },
  py: 4,
};

export default function ModalAddDocumentacion({ open, handleClose, item, setItem }) {
  const [invalidFile, setInvalidFile] = useState(false);
  const [file, setFile] = useState();
  const [nombre, setNombre] = useState("");
  const handleSave = () => {
    let newDocumentacion = item || [];
    newDocumentacion.push({
      nombre: nombre,
      file: file,
      fecha: dayjs().format("DD/MM/YYYY HH:mm"),
    });
    setItem(newDocumentacion);
    handleClose();
  };

  const toggleInput = () => {
    document.getElementById("input-file").click();
  };

  const handleFile = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      if (file.size > 10000000) {
        setInvalidFile(true);
      } else {
        setInvalidFile(false);
      }
    } else {
      setFile();
      setInvalidFile(true);
    }
  };

  useEffect(() => {
    setNombre("");
    setFile();
    setInvalidFile(false);
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox px={3}>
            <Grid container justifyContent="space-between" alignItems="center">
              <SoftTypography variant="h6">Agregar nuevo archivo</SoftTypography>
            </Grid>
          </SoftBox>
          <SoftBox p={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel htmlFor="concepto">
                  <SoftTypography component="label" variant="caption" fontWeight="bold">
                    Nombre
                  </SoftTypography>
                </InputLabel>
                <SoftInput
                  label="Nombre"
                  placeholder="Inserte un nombre para el documento"
                  type="text"
                  name="nombre"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Tooltip
                  title={
                    invalidFile
                      ? "El archivo no cumple los requisitos para ser importado"
                      : file
                      ? "Modificar archivo"
                      : "Cargar archivo"
                  }
                  placement="top"
                >
                  <SoftButton
                    variant="gradient"
                    color={invalidFile ? "error" : file ? "success" : "primary"}
                    onClick={toggleInput}
                    name="recibos"
                    fullWidth
                  >
                    <Icon sx={{ fontWeight: "light" }}>
                      {invalidFile ? "error" : file ? "change_circle" : "cloud_upload"}
                    </Icon>
                    &nbsp;{invalidFile ? "Archivo inválido" : file ? file.name : "Cargar archivo"}
                  </SoftButton>
                </Tooltip>
                <SoftBox sx={{ display: "none" }}>
                  <Input
                    type="file"
                    id="input-file"
                    onChange={handleFile}
                    name="recibos"
                    inputProps={{ multiple: false }}
                  />
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
          <SoftBox display="flex" justifyContent="flex-end" alignItems="center" px={3}>
            <SoftBox mr={2}>
              <SoftButton variant="gradient" color="dark" onClick={handleClose}>
                Cancelar
              </SoftButton>
            </SoftBox>
            <SoftBox>
              <SoftButton
                variant="gradient"
                color="primary"
                onClick={handleSave}
                disabled={!nombre || !file || invalidFile}
              >
                Guardar
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalAddDocumentacion.defaultProps = {
  item: [],
  setItem: () => {
    return null;
  },
};

ModalAddDocumentacion.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  item: PropTypes.object,
  setItem: PropTypes.func,
};
