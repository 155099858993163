import {
  Grid,
  Icon,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Logo from "assets/images/logo.png";
import boxShadows from "assets/theme/base/boxShadows";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import dayjs from "dayjs";
import { jsPDF } from "jspdf";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { formatMoneyPunto } from "utils/formatters";
import logo from "assets/images/logo.png";
import SoftButton from "components/SoftButton";

const monthNames = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "septiembre",
  "octubre",
  "noviembre",
  "diciembre",
];

const ContratoLaboratorio = ({ estudio, contrato }) => {
  const documentsRef = useRef(null);
  const { lg } = boxShadows;

  const handleGeneratePdf = async () => {
    const document = documentsRef.current;

    const doc = new jsPDF({
      format: "a4",
      unit: "px",
      orientation: "portrait",
      hotfixes: ["px_scaling"],
    });

    await doc.html(document, {
      callback(doc) {
        const pageCount = doc.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          const pageSize = doc.internal.pageSize;
          const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          const header = "Contrato NºX-Y";
          const brand = "Kaizen Software";
          const footer = `© ${dayjs().year()} Powered by Kaizen Software Factory`;
          const date = dayjs().format("DD/MM/YYYY");

          // Header
          doc.setFontSize(10);

          if (i !== 1) {
            doc.text(header, 40, 15, { baseline: "top" });
            doc.text(brand, pageWidth - 40 - doc.getTextWidth(brand), 15, { baseline: "top" });
          }

          // Footer
          doc.text(footer, 40, pageHeight - 15, { baseline: "bottom" });
          doc.text(date, pageWidth - 40 - doc.getTextWidth(date), pageHeight - 15, {
            baseline: "bottom",
          });

          // Marca de agua
          doc.setFontSize(80);
          doc.setTextColor(200);

          doc.setGState(new doc.GState({ opacity: 0.5 }));
          const watermarkText = "CONFIDENCIAL";
          const textWidth = doc.getTextWidth(watermarkText);
          const textHeight = doc.internal.getFontSize();

          doc.text(watermarkText, pageWidth / 2 - 260, pageHeight / 2 + 300, {
            angle: 45,
          });
          doc.setGState(new doc.GState({ opacity: 1 }));
        }
        doc.deletePage(pageCount);
        doc.save(`Contrato ${estudio.nombre}.pdf`);
      },
    });
  };

  return (
    <SoftBox
      p={2}
      display="flex"
      alignItems="center"
      flexDirection="column"
      minHeight="90vh"
      height="100%"
      maxHeight="90vh"
      overflow="auto"
    >
      <div ref={documentsRef}>
        <SoftBox
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          sx={{
            position: "relative",
            overflow: "hidden",
            width: "794px",
            height: "1110px",
            backgroundColor: "#ffffff",
            boxShadow: lg,
            px: 6,
            pt: 3,
          }}
          mb={3}
        >
          <SoftBox
            sx={{
              flexGrow: 1,
            }}
          >
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
              <TableContainer>
                <Table sx={{ width: "100%" }} aria-label="simple table">
                  <TableRow>
                    <TableCell colSpan={4}>
                      <SoftTypography variant="h6" fontWeight="bold" textAlign="center">
                        CONTRATO DE OBRA CON LABORATORIOS BIOANALITICOS Y PROVEEDORES DE ANALISIS
                        CLINICOS
                      </SoftTypography>
                    </TableCell>
                    <TableCell>
                      <img src={Logo} alt="Terra" width="120px" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <SoftTypography variant="button" fontWeight="regular">
                        Código
                      </SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button" fontWeight="regular">
                        Fecha de vigencia
                      </SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button" fontWeight="regular">
                        Próxima Revisión
                      </SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button" fontWeight="regular">
                        Páginas
                      </SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button" fontWeight="regular">
                        Versión
                      </SoftTypography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <SoftTypography variant="button">{estudio?.codigo}</SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button">
                        {contrato?.fechaVigencia ? (
                          dayjs(contrato?.fechaVigencia).format("DD/MM/YYYY")
                        ) : (
                          <Skeleton variant="text" width="100%" />
                        )}
                      </SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button">
                        {contrato?.proximaRevision ? (
                          dayjs(contrato?.proximaRevision).format("DD/MM/YYYY")
                        ) : (
                          <Skeleton variant="text" width="100%" />
                        )}
                      </SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button">5</SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button">
                        {contrato?.version || <Skeleton variant="text" width="100%" />}
                      </SoftTypography>
                    </TableCell>
                  </TableRow>
                </Table>
              </TableContainer>
            </SoftBox>

            <SoftBox>
              <SoftBox mb={3} pt={3}>
                <SoftTypography variant="h5" textAlign="center">
                  Contrato de obra entre <br />
                  el laboratorio&nbsp;
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {estudio?.laboratorio?.nombre}
                  </span>
                  <br />
                  y el investigador principal del estudio <br />
                  Protocolo <span style={{ fontWeight: "bold" }}>{estudio?.nombre}</span>
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={3}>
                <SoftTypography variant="h6" textAlign="center">
                  Ciudad de <span style={{ fontWeight: "bold" }}>{estudio?.localidad?.nombre}</span>{" "}
                  - Provincia de{" "}
                  <span style={{ fontWeight: "bold" }}>{estudio?.provincia?.nombre}</span>, a los{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {dayjs(contrato?.fechaVigencia).format("DD")}
                  </span>{" "}
                  días del mes de{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {monthNames[dayjs(contrato?.fechaVigencia).month()]}
                  </span>{" "}
                  de{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {dayjs(contrato?.fechaVigencia).format("YYYY")}
                  </span>
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" textAlign="start">
                  Por una parte, el proveedor bioanalítico{" "}
                  <span style={{ fontWeight: "bold" }}>Alianza Investigación Veterinaria</span> (en
                  adelante PROVEEDOR BIONALÍTICO), con domicilio en{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    <SoftBox display="inline-block">
                      <Skeleton variant="text" width="100px" />
                    </SoftBox>
                  </span>
                  , representado en éste acto por su Responsable{" "}
                  <span
                    style={{ fontWeight: "bold" }}
                  >{`${estudio?.responsable?.nombre} ${estudio?.responsable?.apellido}`}</span>
                  ; DNI <span style={{ fontWeight: "bold" }}>{estudio?.responsable?.dni}</span>;
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" textAlign="start">
                  Y por otra parte, el Investigador Principal/Co-Investigador Principal (en adelante
                  EL INVESTIGADOR), CUIT{" "}
                  <span style={{ fontWeight: "bold" }}>{estudio?.laboratorio?.documento}</span>, con
                  domicilio en{" "}
                  <span
                    style={{ fontWeight: "bold" }}
                  >{`${estudio?.laboratorio?.domicilio}, ${estudio?.laboratorio?.localidad?.nombre}, ${estudio?.laboratorio?.localidad?.provincia?.nombre}`}</span>
                  , representado en éste acto por el Dr./Dra.{" "}
                  <span
                    style={{ fontWeight: "bold" }}
                  >{`${estudio?.laboratorio?.nombreResponsable} ${estudio?.laboratorio?.apellidoResponsable}`}</span>
                  ; DNI{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {estudio?.laboratorio?.documentoResponsable}
                  </span>
                  ;
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" textAlign="start" fontWeight="bold">
                  EXPONEN
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2} ml={2}>
                <SoftTypography variant="h6" textAlign="start">
                  - Que EL PROVEEDOR BIOANALITICO es un agente organizado económicamente y que
                  desarrolla actividades de laboratorio bioanalítico de muestras animales,
                  interesada en colaborar con la ciencia;
                  <br />
                  - Que EL INVESTIGADOR brinda servicios para para el registro de medicamentos
                  veterinarios, demandados por empresas farmacéuticas;
                  <br />- Que tanto EL PROVEEDOR BIOANALITICO como LA EMPRESA están de acuerdo en la
                  participación conjunta de proyectos tanto de ámbito nacional como internacional.
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" textAlign="start">
                  Teniendo en cuenta lo anteriormente expuesto,{" "}
                  <span style={{ fontWeight: "bold" }}>CONVIENEN</span> estrechar sus relaciones,
                  aunar esfuerzos y establecer normas amplias y específicas de actuación que
                  encaucen e incrementen, dentro de un marco preestablecido, los contactos y
                  colaboraciones.
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <SoftBox
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          sx={{
            position: "relative",
            overflow: "hidden",
            width: "794px",
            height: "1110px",
            backgroundColor: "#ffffff",
            boxShadow: lg,
            px: 6,
            pt: 3,
          }}
          mb={3}
        >
          <SoftBox
            sx={{
              flexGrow: 1,
            }}
          >
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
              <TableContainer>
                <Table sx={{ width: "100%" }} aria-label="simple table">
                  <TableRow>
                    <TableCell colSpan={4}>
                      <SoftTypography variant="h6" fontWeight="bold" textAlign="center">
                        CONTRATO DE OBRA CON LABORATORIOS BIOANALITICOS Y PROVEEDORES DE ANALISIS
                        CLINICOS
                      </SoftTypography>
                    </TableCell>
                    <TableCell>
                      <img src={Logo} alt="Terra" width="120px" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <SoftTypography variant="button" fontWeight="regular">
                        Código
                      </SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button" fontWeight="regular">
                        Fecha de vigencia
                      </SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button" fontWeight="regular">
                        Próxima Revisión
                      </SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button" fontWeight="regular">
                        Páginas
                      </SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button" fontWeight="regular">
                        Versión
                      </SoftTypography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <SoftTypography variant="button">{estudio?.codigo}</SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button">
                        {contrato?.fechaVigencia ? (
                          dayjs(contrato?.fechaVigencia).format("DD/MM/YYYY")
                        ) : (
                          <Skeleton variant="text" width="100%" />
                        )}
                      </SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button">
                        {contrato?.proximaRevision ? (
                          dayjs(contrato?.proximaRevision).format("DD/MM/YYYY")
                        ) : (
                          <Skeleton variant="text" width="100%" />
                        )}
                      </SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button">5</SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button">
                        {contrato?.version || <Skeleton variant="text" width="100%" />}
                      </SoftTypography>
                    </TableCell>
                  </TableRow>
                </Table>
              </TableContainer>
            </SoftBox>

            <SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" textAlign="start">
                  A tal fin, se redacta el siguiente acuerdo de colaboración según las siguientes{" "}
                  <span style={{ fontWeight: "bold" }}>CLÁUSULAS</span>:
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" textAlign="start" fontWeight="bold">
                  PRIMERA: Finalidad.
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" textAlign="start">
                  EL INVESTIGADOR contrata a EL PROVEEDOR BIOANALITICO con el objeto de ejecutar un
                  estudio de investigación clínica veterinaria correspondiente al protocolo{" "}
                  <span style={{ fontWeight: "bold" }}>{estudio?.nombre}</span>. La formulación en
                  estudio pertenece al laboratorio patrocinante{" "}
                  <span style={{ fontWeight: "bold" }}>{estudio?.laboratorio?.nombre}</span>, quien
                  delega la ejecución del estudio en EL INVESIGADOR.
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" textAlign="start">
                  La investigación consiste en un estudio de{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {contrato?.motivo || (
                      <SoftBox display="inline-block">
                        <Skeleton variant="text" width="100px" />
                      </SoftBox>
                    )}
                  </span>
                  , de una formulación de prueba a base de{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {contrato?.formulacion || (
                      <SoftBox display="inline-block">
                        <Skeleton variant="text" width="100px" />
                      </SoftBox>
                    )}
                  </span>
                  , en la especie{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {contrato?.especie || (
                      <SoftBox display="inline-block">
                        <Skeleton variant="text" width="100px" />
                      </SoftBox>
                    )}
                  </span>
                  .
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" textAlign="start">
                  El estudio de Investigación Clínica Veterinaria tiene asociado un plan de trabajo
                  que detalla el diseño, las distintas fases a desarrollar y la cronología, como se
                  indica en el protocolo adjunto.
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" textAlign="start" fontWeight="bold">
                  SEGUNDA: Precio.
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" textAlign="start">
                  EL INVESTIGADOR pagará a EL PROVEEDOR BIOANALITICO, el valor de{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {contrato?.precio ? (
                      `$${formatMoneyPunto(contrato?.precio)}`
                    ) : (
                      <SoftBox display="inline-block">
                        <Skeleton variant="text" width="100px" />
                      </SoftBox>
                    )}
                  </span>{" "}
                  por muestra según el precio al momento del estudio. En el caso de animales que por
                  causa ajena a la voluntad del Veterinario, no pudieran terminar el estudio, se
                  abonará a EL PROVEEDOR BIOANALITICO, el valor los tiempos cumplidos del estudio,
                  con toda la información requerida para ese animal.
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" textAlign="start" fontWeight="bold">
                  TERCERA: Responsables.
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" textAlign="start">
                  Por parte de la empresa patrocinante{" "}
                  <span style={{ fontWeight: "bold" }}>{estudio?.laboratorio?.nombre}</span>, se
                  designa al Dr./Dra{" "}
                  <span
                    style={{ fontWeight: "bold" }}
                  >{`${estudio?.laboratorio?.nombreResponsable} ${estudio?.laboratorio?.apellidoResponsable}`}</span>{" "}
                  como Investigador/a principal y contacto a cualquier efecto, siendo su correo
                  electrónico{" "}
                  <span style={{ fontWeight: "bold" }}>{estudio?.laboratorio?.correo}</span> y su
                  teléfono{" "}
                  <span style={{ fontWeight: "bold" }}>{estudio?.laboratorio?.telefono}</span>.
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" textAlign="start">
                  Por parte de EL PROVEEDOR BIOANALITICO se designa al Dr./Dra.
                  <span
                    style={{ fontWeight: "bold" }}
                  >{`${estudio?.responsable?.nombre} ${estudio?.responsable?.apellido}`}</span>
                  ; siendo su correo electrónico{" "}
                  <span style={{ fontWeight: "bold" }}>{estudio?.responsable?.email}</span> y su
                  teléfono{" "}
                  <span style={{ fontWeight: "bold" }}>{estudio?.responsable?.telefono}</span>.
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <SoftBox
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          sx={{
            position: "relative",
            overflow: "hidden",
            width: "794px",
            height: "1110px",
            backgroundColor: "#ffffff",
            boxShadow: lg,
            px: 6,
            pt: 3,
          }}
          mb={3}
        >
          <SoftBox
            sx={{
              flexGrow: 1,
            }}
          >
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
              <TableContainer>
                <Table sx={{ width: "100%" }} aria-label="simple table">
                  <TableRow>
                    <TableCell colSpan={4}>
                      <SoftTypography variant="h6" fontWeight="bold" textAlign="center">
                        CONTRATO DE OBRA CON LABORATORIOS BIOANALITICOS Y PROVEEDORES DE ANALISIS
                        CLINICOS
                      </SoftTypography>
                    </TableCell>
                    <TableCell>
                      <img src={Logo} alt="Terra" width="120px" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <SoftTypography variant="button" fontWeight="regular">
                        Código
                      </SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button" fontWeight="regular">
                        Fecha de vigencia
                      </SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button" fontWeight="regular">
                        Próxima Revisión
                      </SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button" fontWeight="regular">
                        Páginas
                      </SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button" fontWeight="regular">
                        Versión
                      </SoftTypography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <SoftTypography variant="button">{estudio?.codigo}</SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button">
                        {contrato?.fechaVigencia ? (
                          dayjs(contrato?.fechaVigencia).format("DD/MM/YYYY")
                        ) : (
                          <Skeleton variant="text" width="100%" />
                        )}
                      </SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button">
                        {contrato?.proximaRevision ? (
                          dayjs(contrato?.proximaRevision).format("DD/MM/YYYY")
                        ) : (
                          <Skeleton variant="text" width="100%" />
                        )}
                      </SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button">5</SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button">
                        {contrato?.version || <Skeleton variant="text" width="100%" />}
                      </SoftTypography>
                    </TableCell>
                  </TableRow>
                </Table>
              </TableContainer>
            </SoftBox>

            <SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" textAlign="start" fontWeight="bold">
                  CUARTA: Duración.
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" textAlign="start">
                  El presente Acuerdo Marco entrará en vigor en el momento de su firma y su vigencia
                  será por el tiempo que dure la etapa clínica con EL PROVEEDOR BIOANALITICO.
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" textAlign="start" fontWeight="bold">
                  QUINTA: Terminación y rescisión.{" "}
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" textAlign="start">
                  El presente convenio puede ser rescindido sin expresión de causa por cualquiera de
                  las partes mediante un aviso previo, por medio fehaciente, realizado con una
                  anticipación no menor a los treinta (30) días de la fecha en que se pretende su
                  terminación. La terminación comunicada en tiempo y forma conforme lo dispuesto en
                  la presente cláusula no dará derecho a reclamar indemnización de ninguna
                  naturaleza a ninguna de las partes. En caso de existir trabajos en curso de
                  ejecución las partes se comprometen a mantener en vigencia el convenio específico
                  hasta la finalización de las actividades previstas. En caso de no ser posible por
                  causas verificables, se tratará de llegar a un acuerdo de finalización anticipada
                  de proyectos. El INVESTIGADOR se compromete a pagar por los servicios contratados
                  a EL PROVEEDOR BIOANALITICO, conforme a lo establecido en el apartado “PRECIO” al
                  momento de rescindir el acuerdo, en contraprestación a lo ya realizado.
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" textAlign="start" fontWeight="bold">
                  SEXTA: Controversias.
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" textAlign="start">
                  EL PROVEEDOR BIOANALITICO y EL INVESTIGADOR se comprometen a resolver de manera
                  amistosa cualquier desacuerdo que pueda surgir en el desarrollo del presente
                  contrato.
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" textAlign="start">
                  En caso de conflicto, se acuerda el sometimiento de ambos a los tribunales
                  federales de la ciudad de locación de EL PROVEEDOR BIOANALITICO y las cámaras de
                  mediación dispuestas a tales efectos.
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" textAlign="start" fontWeight="bold">
                  SEPTIMA: Confidencialidad.
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" textAlign="start">
                  Las partes reconocen que toda la documentación e información relacionada y
                  generada como consecuencia de éste acuerdo de colaboración como así también toda
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <SoftBox
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          sx={{
            position: "relative",
            overflow: "hidden",
            width: "794px",
            height: "1110px",
            backgroundColor: "#ffffff",
            boxShadow: lg,
            px: 6,
            pt: 3,
          }}
          mb={3}
        >
          <SoftBox
            sx={{
              flexGrow: 1,
            }}
          >
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
              <TableContainer>
                <Table sx={{ width: "100%" }} aria-label="simple table">
                  <TableRow>
                    <TableCell colSpan={4}>
                      <SoftTypography variant="h6" fontWeight="bold" textAlign="center">
                        CONTRATO DE OBRA CON LABORATORIOS BIOANALITICOS Y PROVEEDORES DE ANALISIS
                        CLINICOS
                      </SoftTypography>
                    </TableCell>
                    <TableCell>
                      <img src={Logo} alt="Terra" width="120px" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <SoftTypography variant="button" fontWeight="regular">
                        Código
                      </SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button" fontWeight="regular">
                        Fecha de vigencia
                      </SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button" fontWeight="regular">
                        Próxima Revisión
                      </SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button" fontWeight="regular">
                        Páginas
                      </SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button" fontWeight="regular">
                        Versión
                      </SoftTypography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <SoftTypography variant="button">{estudio?.codigo}</SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button">
                        {contrato?.fechaVigencia ? (
                          dayjs(contrato?.fechaVigencia).format("DD/MM/YYYY")
                        ) : (
                          <Skeleton variant="text" width="100%" />
                        )}
                      </SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button">
                        {contrato?.proximaRevision ? (
                          dayjs(contrato?.proximaRevision).format("DD/MM/YYYY")
                        ) : (
                          <Skeleton variant="text" width="100%" />
                        )}
                      </SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button">5</SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button">
                        {contrato?.version || <Skeleton variant="text" width="100%" />}
                      </SoftTypography>
                    </TableCell>
                  </TableRow>
                </Table>
              </TableContainer>
            </SoftBox>
            <SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" textAlign="start">
                  la información que se intercambie por el hecho o en ocasión del presente, la que
                  se obtenga durante la ejecución de proyectos y el resultado del mismo (en adelante
                  LA INFORMACION CONFIDENCIAL) es de exclusiva propiedad de las partes y se
                  considerará confidencial. En tal sentido, se comprometen a:
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2} ml={2}>
                <SoftTypography variant="h6" textAlign="start">
                  7.1. No usar LA INFORMACION CONFIDENCIAL para otro propósito o fin diferente a la
                  realización del estudio.
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2} ml={2}>
                <SoftTypography variant="h6" textAlign="start">
                  7.2. No utilizar LA INFORMACION CONFIDENCIAL para cualquier otro fin sin el previo
                  consentimiento escrito de la otra parte.
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2} ml={2}>
                <SoftTypography variant="h6" textAlign="start">
                  7.3. Mantener en estricto secreto LA INFORMACION CONFIDENCIAL y restringir el
                  acceso a LA INFORMACION sólo a aquellas personas a su cargo y por ellos designadas
                  para realizar el estudio, siendo exclusivos responsables de garantizar la
                  confidencialidad de LA INFORMACION CONFIDENCIAL por parte de dichas personas.
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" textAlign="start">
                  Quedarán exceptuados de la obligación de confidencialidad respecto de LA
                  INFORMACION CONFIDENCIAL sólo en los siguientes supuestos:
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2} ml={2}>
                <SoftTypography variant="h6" textAlign="start">
                  a) Se encuentre en el dominio público al tiempo de la celebración del presente
                  acuerdo;
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2} ml={2}>
                <SoftTypography variant="h6" textAlign="start">
                  b) Sea publicado o se torne de dominio público por causas no imputables a las
                  partes;
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2} ml={2}>
                <SoftTypography variant="h6" textAlign="start">
                  c) Esté en poder de algunas de las partes al tiempo de la celebración del presente
                  acuerdo;
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2} ml={2}>
                <SoftTypography variant="h6" textAlign="start">
                  d) Medie requerimiento judicial o de organismo público competente.
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" textAlign="start">
                  Las partes reconocen expresamente que las funciones que desarrollarán en relación
                  con las obligaciones consignadas en el presente acuerdo, los pondrán en contacto
                  con los secretos industriales, técnicos, operacionales y comerciales de cada una
                  de ellas, así como de aquellos pertenecientes a sus socios, asociados,
                  accionistas, filiales, subsidiarias, controladoras, clientes, contratistas y
                  cualesquier otra persona relacionada, además del resto de información privilegiada
                  de sus operaciones.
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <SoftBox
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          sx={{
            position: "relative",
            overflow: "hidden",
            width: "794px",
            height: "1110px",
            backgroundColor: "#ffffff",
            boxShadow: lg,
            px: 6,
            pt: 3,
          }}
          mb={3}
        >
          <SoftBox
            sx={{
              flexGrow: 1,
            }}
          >
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
              <TableContainer>
                <Table sx={{ width: "100%" }} aria-label="simple table">
                  <TableRow>
                    <TableCell colSpan={4}>
                      <SoftTypography variant="h6" fontWeight="bold" textAlign="center">
                        CONTRATO DE OBRA CON LABORATORIOS BIOANALITICOS Y PROVEEDORES DE ANALISIS
                        CLINICOS
                      </SoftTypography>
                    </TableCell>
                    <TableCell>
                      <img src={Logo} alt="Terra" width="120px" />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <SoftTypography variant="button" fontWeight="regular">
                        Código
                      </SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button" fontWeight="regular">
                        Fecha de vigencia
                      </SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button" fontWeight="regular">
                        Próxima Revisión
                      </SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button" fontWeight="regular">
                        Páginas
                      </SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button" fontWeight="regular">
                        Versión
                      </SoftTypography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      <SoftTypography variant="button">{estudio?.codigo}</SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button">
                        {contrato?.fechaVigencia ? (
                          dayjs(contrato?.fechaVigencia).format("DD/MM/YYYY")
                        ) : (
                          <Skeleton variant="text" width="100%" />
                        )}
                      </SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button">
                        {contrato?.proximaRevision ? (
                          dayjs(contrato?.proximaRevision).format("DD/MM/YYYY")
                        ) : (
                          <Skeleton variant="text" width="100%" />
                        )}
                      </SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button">5</SoftTypography>
                    </TableCell>
                    <TableCell align="center">
                      <SoftTypography variant="button">
                        {contrato?.version || <Skeleton variant="text" width="100%" />}
                      </SoftTypography>
                    </TableCell>
                  </TableRow>
                </Table>
              </TableContainer>
            </SoftBox>
            <SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" textAlign="start">
                  Por lo anterior, serán considerados “secretos”, incluyendo, de manera enunciativa
                  más no limitativa, todos aquellos conocimientos industriales, técnicos,
                  comerciales y operacionales, conceptos creativos, propuestas, estrategias de
                  comunicación y publicidad, marcas, diseños, modelos, base de datos, listas de
                  precios, registros, datos, materiales, planes y proyectos de comercialización y
                  ventas, publicidad e información de cualquier tipo relacionada con los negocios y
                  operaciones de las empresas y/o personas relacionadas directa o indirectamente con
                  ésta.
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" textAlign="start">
                  La obligación de confidencialidad asumida en éste apartado es a perpetuidad. El
                  incumplimiento de la obligación de confidencialidad, hará responsable a la parte
                  divulgadora de responder integralmente por los daños y perjuicios sufridos por la
                  otra parte como consecuencia de cualquier acto o hecho violatorio de tal
                  obligación.
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2}>
                <SoftTypography variant="h6" textAlign="start">
                  En prueba de conformidad, firman por duplicado el presente acuerdo.
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </div>
      <SoftBox>
        <SoftButton
          variant="gradient"
          color="error"
          id="descargarPDF"
          onClick={handleGeneratePdf}
          sx={{
            visibility: "hidden",
          }}
        >
          <Icon fontWeight="bold">picture_as_pdf</Icon>
          &nbsp;&nbsp;Descargar PDF
        </SoftButton>
      </SoftBox>
    </SoftBox>
  );
};

ContratoLaboratorio.propTypes = {
  estudio: PropTypes.object,
  contrato: PropTypes.object,
};

export default ContratoLaboratorio;
