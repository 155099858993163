// React
import PropTypes from "prop-types";
import { useEffect } from "react";

// @mui material components
import { Grid, InputLabel, MenuItem, Select } from "@mui/material";

import { gql, useLazyQuery, useQuery } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "60%", xxl: "50%" },
  overflowY: "auto",
  p: 3,
};

export default function EstudioDetalles({ estudio, setEstudio, large }) {
  const { data: laboratorios } = useQuery(
    gql`
      query getProveedores {
        laboratorios {
          id
          nombre
          domicilio
          documento
          correo
          telefono
          localidad{
            nombre
            provincia{
              nombre
            }
          }
          nombreResponsable
          apellidoResponsable
          documentoResponsable
        }
      }
    `
  );

  const { data: usuarios } = useQuery(
    gql`
      query getUsuarios {
        usuarios {
          id
          nombre
          apellido
          dni
        }
      }
    `
  );

  const { data: provincias } = useQuery(gql`
    query GetProvincias {
      provincias {
        id
        nombre
      }
    }
  `);

  const [getLocalidades, { data: localidades }] = useLazyQuery(gql`
    query GetLocalidades($provincia: ID!) {
      localidades(provincia: $provincia) {
        id
        nombre
        provincia {
          id
        }
      }
    }
  `);

  useEffect(() => {
    if (estudio?.idProvincia) {
      getLocalidades({ variables: { provincia: estudio?.idProvincia } }).then((data) => {
        if (!data?.data?.localidades?.find((localidad) => localidad.id === estudio?.idLocalidad)) {
          let proyectoNew = { ...estudio };
          delete proyectoNew.idLocalidad;
          setEstudio(proyectoNew);
        }
      });
    }
  }, [estudio?.idProvincia]);

  return (
    <SoftBox>
      {large && (
        <SoftBox display="flex" justifyContent="space-between" mb={2}>
          <SoftBox>
            <SoftTypography variant="h6">{`Nuevo estudio - Detalles del estudio`}</SoftTypography>
          </SoftBox>
        </SoftBox>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={large ? 4 : 6}>
          <InputLabel htmlFor="nombre">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Nombre del estudio
              <SoftTypography variant="caption" fontWeight="bold" color="primary">
                &nbsp;*
              </SoftTypography>
            </SoftTypography>
          </InputLabel>
          <SoftInput
            label="nombre"
            placeholder="Ingrese el nombre del estudio"
            type="text"
            name="nombre"
            value={estudio?.nombre}
            onChange={(e) => setEstudio({ ...estudio, nombre: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={large ? 4 : 6}>
          <InputLabel htmlFor="codigo">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Código
              <SoftTypography variant="caption" fontWeight="bold" color="primary">
                &nbsp;*
              </SoftTypography>
            </SoftTypography>
          </InputLabel>
          <SoftInput
            label="codigo"
            placeholder="Ingrese el código del estudio"
            type="text"
            name="codigo"
            value={estudio?.codigo}
            onChange={(e) => setEstudio({ ...estudio, codigo: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={large ? 4 : 6}>
          <InputLabel htmlFor="proveedor">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Laboratorio
              <SoftTypography variant="caption" fontWeight="bold" color="primary">
                &nbsp;*
              </SoftTypography>
            </SoftTypography>
          </InputLabel>
          <Select
            fullWidth
            label="Laboratorio"
            value={estudio?.idLaboratorio || -1}
            onChange={(e) =>
              setEstudio({
                ...estudio,
                idLaboratorio: e.target.value,
                laboratorio: laboratorios?.laboratorios.find(
                  (laboratorio) => laboratorio.id === e.target.value
                ),
              })
            }
          >
            <MenuItem value={-1} disabled>
              Seleccione un laboratorio
            </MenuItem>
            {laboratorios?.laboratorios.map((laboratorio) => (
              <MenuItem key={laboratorio.id} value={laboratorio.id}>
                {laboratorio.nombre}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={large ? 4 : 6}>
          <InputLabel htmlFor="responsable">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Responsable
              <SoftTypography variant="caption" fontWeight="bold" color="primary">
                &nbsp;*
              </SoftTypography>
            </SoftTypography>
          </InputLabel>
          <Select
            fullWidth
            label="Responsable"
            value={estudio?.idResponsable || -1}
            onChange={(e) =>
              setEstudio({
                ...estudio,
                idResponsable: e.target.value,
                responsable: usuarios?.usuarios.find((user) => user.id === e.target.value),
              })
            }
          >
            <MenuItem value={-1} disabled>
              Seleccione un responsable
            </MenuItem>
            {usuarios?.usuarios.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.nombre} {user.apellido}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={large ? 4 : 6}>
          <InputLabel htmlFor="provincia">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Provincia
              <SoftTypography variant="caption" fontWeight="bold" color="primary">
                &nbsp;*
              </SoftTypography>
            </SoftTypography>
          </InputLabel>
          <Select
            name="provincia"
            value={estudio?.idProvincia || -1}
            onChange={(e) =>
              setEstudio({
                ...estudio,
                idProvincia: e.target.value,
                provincia: provincias?.provincias.find(
                  (provincia) => provincia.id === e.target.value
                ),
              })
            }
          >
            <MenuItem value={-1} disabled>
              Seleccione uno
            </MenuItem>
            {provincias?.provincias?.map((provincia) => (
              <MenuItem key={provincia.id} value={provincia.id}>
                {provincia.nombre}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={large ? 4 : 6}>
          <InputLabel htmlFor="localidad">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Localidad
              <SoftTypography variant="caption" fontWeight="bold" color="primary">
                &nbsp;*
              </SoftTypography>
            </SoftTypography>
          </InputLabel>
          <Select
            name="localidad"
            value={estudio?.idLocalidad || -1}
            onChange={(e) =>
              setEstudio({
                ...estudio,
                idLocalidad: e.target.value,
                localidad: localidades?.localidades.find(
                  (localidad) => localidad.id === e.target.value
                ),
              })
            }
          >
            <MenuItem value={-1} disabled>
              Seleccione uno
            </MenuItem>
            {localidades?.localidades?.map((localidad) => (
              <MenuItem key={localidad.id} value={localidad.id}>
                {localidad.nombre}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

EstudioDetalles.propTypes = {
  estudio: PropTypes.object,
  setEstudio: PropTypes.func,
  large: PropTypes.bool,
};
