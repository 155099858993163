// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import { Icon, Tooltip } from "@mui/material";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

function DocumentacionItem({ name, description, file, handleDelete, url }) {
  const handleDownload = () => {
    if (url) {
      window.open(url, "_blank");
    } else {
      const blob = new Blob([file], { type: file.type });
      const elem = window.document.createElement("a");
      elem.href = window.URL.createObjectURL(blob);
      elem.download = name;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  };

  return (
    <SoftBox key={name} component="li" py={1} mb={1}>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center">
        <SoftBox display="flex" alignItems="center">
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="button" fontWeight="medium" gutterBottom>
              {name}
            </SoftTypography>
            <SoftTypography variant="caption" color="text">
              {description}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox display="flex" justifyContent="center" alignItems="center">
          <SoftBox mr={1}>
            <Tooltip title="Eliminar" placement="top">
              <SoftButton
                variant="outlined"
                color="error"
                size="small"
                iconOnly
                circular
                onClick={handleDelete}
              >
                <Icon sx={{ fontWeight: "bold" }}>close</Icon>
              </SoftButton>
            </Tooltip>
          </SoftBox>
          <SoftBox>
            <Tooltip title="Descargar" placement="top">
              <SoftButton
                variant="outlined"
                color="info"
                size="small"
                iconOnly
                circular
                onClick={handleDownload}
              >
                <Icon sx={{ fontWeight: "bold" }}>file_download</Icon>
              </SoftButton>
            </Tooltip>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

// Typechecking props of the DocumentacionItem
DocumentacionItem.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  file: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,
  url: PropTypes.string,
};

export default DocumentacionItem;
