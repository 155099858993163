// React
import { gql, useMutation, useQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

// Components
import { Card, Fade, Icon, Menu, MenuItem, Tooltip, Modal } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Data
// import dataUsuarios from "layouts/usuarios/data/dataUsuarios";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import CustomPagination from "components/Pagination";
import DataTable from "components/Tables/DataTable";
import { getKaizenColor } from "utils/colors";
import dataVeterinarias from "../data/dataVeterinarias";
// import ModalUsuarios from "./ModalUsuario";
import { MessageManager } from "context";
import { UserContext } from "context/user";
import dataSujetos from "../data/dataSujetos";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "90%", xxl: "60%" },
  overflow: "auto",
  p: 3,
};

export default function ModalSujetos({ open, handleClose, idveterinaria }) {
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUsuario, setSelectedUsuario] = useState(null);
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState({
    pages: 0,
    total: 0,
  });
  const [orderBy, setOrderBy] = useState({
    campo: "nombre",
    orden: "ASC",
  });
  const { handleSnackbar } = useContext(MessageManager);
  const { verificarAcceso } = useContext(UserContext);
  const { columns, getRows } = dataSujetos;
  const dataDemo = [
    {
        id: "1",
        tipo: "Canino",
        nombre: "Firulais",
        raza: "Golden",
        edad: "5 años",
        sexo: "Macho",
        estado: "En tratamiento",
    },
    {
        id: "2",
        tipo: "Felino",
        nombre: "Michi",
        raza: "Siames",
        edad: "3 años",
        sexo: "Hembra",
        estado: "En tratamiento",
    }
  ]

  const handleOrderBy = (campo) => {
    if (orderBy.campo === campo) {
      if (orderBy.orden === "ASC") {
        setOrderBy({
          campo: campo,
          orden: "DESC",
        });
      } else {
        setOrderBy({
          campo: campo,
          orden: "ASC",
        });
      }
    } else {
      setOrderBy({
        campo: campo,
        orden: "ASC",
      });
    }
  };

  const handleOpenMenu = (event, item) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
    setSelectedUsuario(item);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const [deleteveterinaria] = useMutation(
    gql`
      mutation deleteveterinaria($id: ID!) {
        deleteveterinaria(id: $id) {
          id
        }
      }
    `
  );

  const { loading, error, data, refetch } = useQuery(
    gql`
      query getVeterinarias($search: String, $page: Int, $order: Order) {
        paginationInfo {
          pages
          total
        }
        veterinarias(search: $search, pagination: { page: $page, limit: 10 }, order: $order) {
          id
          nombre
          nombreResponsable
          apellidoResponsable
          tipoDocumento
          documento
          documentoResponsable
          domicilio
          telefono
          correo
          localidad {
            id
            nombre
            provincia {
              id
              nombre
            }
          }
        }
      }
    `,
    {
      variables: {
        search: search || null,
        page: page,
        order: { field: orderBy.campo, order: orderBy.orden },
      },
      fetchPolicy: "network-only",
    }
  );

  const handleDeleteUser = async (id) => {
    try {
      await deleteveterinaria({ variables: { id } });
      refetch();
      handleSnackbar("Veterinaria eliminado correctamente", "success");
    } catch (error) {
      handleSnackbar("Error al eliminar veterinaria", "error");
    }
  };

  useEffect(() => {
    if (data) {
      setRows(getRows(dataDemo, handleOpenMenu));
      setPagesInfo(data.paginationInfo);
    }
  }, [data]);
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Card sx={style}>
            <SoftBox display="flex" justifyContent="space-between">
              <SoftBox>
                <SoftTypography variant="h6">Sujetos</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox>
              <SoftBox
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                {loading ? (
                  <Loading />
                ) : rows?.length > 0 ? (
                  <DataTable
                    columns={columns}
                    rows={rows}
                    order={orderBy}
                    setOrder={handleOrderBy}
                  />
                ) : (
                  <SinDatos />
                )}
              </SoftBox>
              <CustomPagination
                loading={loading}
                length={data?.veterinarias.length}
                total={pagesInfo?.total}
                pages={pagesInfo?.pages}
                page={page}
                setPage={setPage}
                noCard
              />
            </SoftBox>
          </Card>
        </Fade>
      </Modal>
    </>
  );
}

ModalSujetos.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  idveterinaria: PropTypes.string,
};
