// React
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// @mui material components
import {
  Card,
  Fade,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import { validateEmail } from "utils/validations";
import dataEstudios from "layouts/estudios/data/dataEstudios";
import Loading from "components/Carteles/Loading";
import DataTable from "components/Tables/DataTable";
import SinDatos from "components/Carteles/SinDatos";
import { gql, useQuery } from "@apollo/client";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "40%", xxl: "30%" },
  overflowY: "auto",
  p: 3,
};

export default function ModalAsignarVeterinaria({ open, handleClose, estudio }) {
  const [veterinaria, setVeterinaria] = useState(null);
  const { data: veterinarias } = useQuery(
    gql`
      query getVeterinarias {
        veterinarias {
          id
          nombre
        }
      }
    `
  );
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftBox display="flex" justifyContent="space-between" mb={2}>
              <SoftBox>
                <SoftTypography variant="h6">Asignar veterinaria</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftBox>
                  <Tooltip title="Cerrar" placement="top">
                    <Icon fontSize="medium" onClick={handleClose} sx={{ cursor: "pointer" }}>
                      close
                    </Icon>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox>
              <InputLabel htmlFor="proveedor">
                <SoftTypography component="label" variant="caption" fontWeight="bold">
                  Veterinaria
                  <SoftTypography variant="caption" fontWeight="bold" color="primary">
                    &nbsp;*
                  </SoftTypography>
                </SoftTypography>
              </InputLabel>
              <Select
                fullWidth
                label="Veterinaria"
                value={veterinaria || -1}
                onChange={(e) => setVeterinaria(e.target.value)}
              >
                <MenuItem value={-1} disabled>
                  Seleccione una veterinaria
                </MenuItem>
                {veterinarias?.veterinarias.map((veterinaria) => (
                  <MenuItem key={veterinaria.id} value={veterinaria.id}>
                    {veterinaria.nombre}
                  </MenuItem>
                ))}
              </Select>
            </SoftBox>
            <SoftBox mt={3} display="flex" justifyContent="flex-end">
              <SoftBox mr={2}>
                <SoftButton circular color="kaizenPrimary" onClick={handleClose}>
                  Cancelar&nbsp;
                  <Icon fontSize="small">close</Icon>
                </SoftButton>
              </SoftBox>
              <SoftBox>
                <SoftButton circular color="primary" onClick={handleClose}>
                  Asignar&nbsp;
                  <Icon fontSize="small">save</Icon>
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalAsignarVeterinaria.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  estudio: PropTypes.object,
};
