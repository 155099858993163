import { forwardRef } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// custom styles for the ChatMessage
import { menuItem, menuImage, sentStyle } from "./styles";
import kaizenIcon from "assets/images/icon_kaizen.png";
import terraIcon from "assets/images/icon.png";

const ChatMessage = forwardRef(
  ({ color, title, description, date, type, adjunto, ...rest }, ref) => (
    <MenuItem
      {...rest}
      ref={ref}
      sx={type === "sent" ? (theme) => sentStyle(theme) : (theme) => menuItem(theme)}
    >
      {type === "received" && (
        <SoftBox
          width="2.25rem"
          height="2.25rem"
          mt={0.25}
          mb={0.25}
          borderRadius="lg"
          sx={(theme) => menuImage(theme, { color })}
        >
          <img src={kaizenIcon} alt="kaizen" />
        </SoftBox>
      )}
      <SoftBox mx={2}>
        <SoftBox textAlign={type === "sent" ? "end" : "start"}>
          <SoftTypography variant="button" textTransform="capitalize" fontWeight="regular">
            <strong>{title}</strong>
          </SoftTypography>
        </SoftBox>
        <SoftBox textAlign={type === "sent" ? "end" : "start"}>
          <SoftTypography variant="button" fontWeight="regular">
            {description}
          </SoftTypography>
        </SoftBox>
        <SoftBox textAlign={type === "sent" ? "end" : "start"}>
          <SoftBox>
            <SoftTypography
              variant="caption"
              color="secondary"
              sx={{
                verticalAlign: "middle",
              }}
            >
              <SoftTypography variant="button" color="secondary">
                <Icon
                  sx={{
                    lineHeight: 1.2,
                    mr: 0.5,
                  }}
                >
                  watch_later
                </Icon>
              </SoftTypography>
              {date}
            </SoftTypography>
            {adjunto && (
            <SoftBox
              ml={2}
              component="a"
              href={`https://tickets.esamba.online/${adjunto}`}
              target="_blank"
              rel="noreferrer"
            >
              <SoftTypography
                variant="caption"
                color="secondary"
                sx={{
                  verticalAlign: "middle",
                }}
              >
                <SoftTypography variant="button" color="secondary">
                  <Icon
                    sx={{
                      lineHeight: 1.2,
                      mr: 0.5,
                    }}
                  >
                    attachment
                  </Icon>
                </SoftTypography>
                Archivo adjunto
              </SoftTypography>
            </SoftBox>
          )}
          </SoftBox>
        </SoftBox>
      </SoftBox>
      {type === "sent" && (
        <SoftBox
          width="2.25rem"
          height="2.25rem"
          mt={0.25}
          mr={2}
          mb={0.25}
          borderRadius="lg"
          sx={(theme) => menuImage(theme, { color })}
        >
          <img src={terraIcon} alt="kaizen" />
        </SoftBox>
      )}
    </MenuItem>
  )
);

// Setting default values for the props of ChatMessage
ChatMessage.defaultProps = {
  color: "dark",
};

// Typechecking props for the ChatMessage
ChatMessage.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["sent", "received"]).isRequired,
  adjunto: PropTypes.string,
};

export default ChatMessage;
