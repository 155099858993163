import { forwardRef } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// custom styles for the NotificationItem
import { menuItem, menuImage } from "components/Items/NotificationItem/styles";
import { formatDate } from "utils/formatters";
import dayjs from "dayjs";
import { Tooltip } from "@mui/material";

const esHoy = (fecha) => {
  const hoy = new Date();
  return (
    fecha.getDate() == hoy.getDate() &&
    fecha.getMonth() == hoy.getMonth() &&
    fecha.getFullYear() == hoy.getFullYear()
  );
};

const NotificationItem = forwardRef(({ titulo, vencimiento, vista, onClickFunc, ...rest }, ref) => (
  <MenuItem {...rest} ref={ref} sx={(theme) => menuItem(theme)} onClick={onClickFunc}>
      <SoftBox
        width="2.25rem"
        height="2.25rem"
        mt={0.25}
        mr={2}
        mb={0.25}
        borderRadius="lg"
        sx={(theme) =>
          menuImage(theme, {
            color: esHoy(dayjs(vencimiento).toDate())
              ? "primary"
              : new Date() > dayjs(vencimiento).toDate()
              ? "error"
              : "dark",
          })
        }
      >
        <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
          {esHoy(dayjs(vencimiento).toDate()) ? "notifications" : "notifications_off"}
        </Icon>
      </SoftBox>
      <SoftBox>
        <SoftTypography variant="button" textTransform="capitalize" fontWeight="regular">
          {titulo.length > 20 ? titulo.substring(0, 20) + "..." : titulo}
        </SoftTypography>
        <SoftTypography
          variant="caption"
          color="secondary"
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 0.5,
          }}
        >
          <SoftTypography variant="button" color="secondary">
            <Icon
              sx={{
                lineHeight: 1.2,
                mr: 0.5,
              }}
            >
              watch_later
            </Icon>
          </SoftTypography>
          {formatDate(dayjs(vencimiento).toDate(), "XXXX")}
          {new Date().setHours(0, 0, 0, 0) > dayjs(vencimiento).toDate() && " (vencido)"}
        </SoftTypography>
      </SoftBox>
      <SoftBox display="flex" alignItems="center" ml="auto">
        {!vista && <Icon fontSize="md">notifications_active</Icon>}
      </SoftBox>
    </MenuItem>
));

// Typechecking props for the NotificationItem
NotificationItem.propTypes = {
  titulo: PropTypes.string.isRequired,
  vencimiento: PropTypes.string.isRequired,
  vista: PropTypes.bool.isRequired,
  onClickFunc: PropTypes.func.isRequired,
};

export default NotificationItem;
