// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// @mui material components
import TableRow from "@mui/material/TableRow";

// Kaizen Dashboard base styles
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import { useEffect, useState } from "react";
import { makeNiceText } from "utils/formatters";
import { formatMoneyPunto } from "utils/formatters";
import Check from "./resource/check.png";
import Cancel from "./resource/cancel.png";
import Material from "./resource/material.png";
import Consumible from "./resource/consumible.png";
import Recurso from "./resource/recurso.png";

function TableItem({ material }) {
  const { light } = colors;
  const { borderWidth } = borders;

  return (
    <TableRow hover>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        <SoftBox
          display="flex"
          alignItems="center"
          sx={{
            cursor: "pointer",
          }}
        >
          <SoftBox pl={3} mr={2} display="flex">
            <img 
              width={26 }
              src={
                material.tipo === "Material" ? Material
                : material.tipo === "Consumible" ? Consumible
                : Recurso
              }
            />
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography verticalAlign="middle" variant="caption" color="dark" fontWeight="bold">
          {material?.id}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography
          verticalAlign="middle"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          {material?.nombre?.length > 50
            ? makeNiceText(material?.nombre).slice(0, 50) + "..."
            : makeNiceText(material?.nombre)}
          {material?.disabled && (
            <SoftTypography
              verticalAlign="middle"
              variant="caption"
              color="error"
              fontWeight="medium"
              sx={{ cursor: "pointer" }}
            >
              &nbsp; (Deshabilitado)
            </SoftTypography>
          )}
        </SoftTypography>
      </SoftBox>
      {/* <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography
          verticalAlign="middle"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          {material?.categoria ? makeNiceText(material?.categoria) : "Sin rubro"}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"left"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        sx={{
          verticalAlign: "bottom",
        }}
      >
        <SoftTypography
          verticalAlign="middle"
          variant="caption"
          color="secondary"
          fontWeight="medium"
        >
          {material?.marca ? makeNiceText(material?.marca, "nombre") : "Sin marca"}
        </SoftTypography>
      </SoftBox> */}
      <SoftBox
        component="td"
        p={1}
        textAlign={"right"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        <SoftTypography
          verticalAlign="middle"
          variant="caption"
          color="dark"
          fontWeight="bold"
        >
          {formatMoneyPunto(material?.enviado || 0)}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        textAlign={"right"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        <SoftTypography
          verticalAlign="middle"
          variant="caption"
          color="dark"
          fontWeight="bold"
        >
          {material?.recibido || material?.recibido == 0 ? formatMoneyPunto(material?.recibido || 0) : ""}
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="td"
        p={1}
        px={3}
        textAlign={"center"}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        {!material?.recibido && material?.recibido != 0 ? null : (
          <SoftBox display="flex" >
            <img width={20} height={20} src={material?.enviado == material?.recibido ? Check : Cancel} alt="" />
          </SoftBox>
        )}
      </SoftBox>
    </TableRow>
  );
}

// Typechecking props of the TableItem
TableItem.propTypes = {
  material: PropTypes.object.isRequired,
};

export default TableItem;
