

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useContext, useReducer, useMemo, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// The Kaizen Dashboard PRO Material main context
const SoftUI = createContext(null);

// Setting custom name for the context which is visible on react dev tools
SoftUI.displayName = "SoftUIContext";

// Kaizen Dashboard reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "OPEN_ONLINE": {
      return { ...state, openOnline: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Kaizen Dashboard context provider
function SoftUIControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: true,
    sidenavColor: "primary",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    openOnline: false,
    direction: "ltr",
    layout: "dashboard",
  };

  const [ocultarSidenav, setOcultarSidenav] = useState(false);

  const toggleOcultarSidenav = () => {
    setOcultarSidenav(!ocultarSidenav);
  };

  const [controller, dispatch] = useReducer(reducer, {
    ...initialState,
    ocultarSidenav,
    toggleOcultarSidenav,
  });

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);
  return <SoftUI.Provider value={value}>{children}</SoftUI.Provider>;
}

const MessageManager = createContext(null);

function MessageManagerProvider({ children }) {
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("error");

  const handleSnackbar = (message, type) => {
    setSnackbar(true);
    setSnackbarMessage(message);
    setSnackbarType(type);
  };

  const closeSnackbar = () => {
    setSnackbar(false);
  }

  const value = {snackbar, snackbarMessage, snackbarType, handleSnackbar, closeSnackbar, setSnackbarMessage};

  return <MessageManager.Provider value={value}>{children}</MessageManager.Provider>;

};

// Kaizen Dashboard custom hook for using context
function useSoftUIController() {
  const context = useContext(SoftUI);

  if (!context) {
    throw new Error("useSoftUIController should be used inside the SoftUIControllerProvider.");
  }

  return context;
}


// Typechecking props for the SoftUIControllerProvider
SoftUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

MessageManagerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setOpenOnlineUsers = (dispatch, value) => dispatch({ type: "OPEN_ONLINE", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });

export {
  SoftUIControllerProvider,
  useSoftUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setOpenOnlineUsers,
  setDirection,
  setLayout,
  MessageManagerProvider,
  MessageManager,
};
