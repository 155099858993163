// React
import PropTypes from "prop-types";

// @mui material components
import { Grid, Icon } from "@mui/material";

import MiniStatisticsCard from "components/Cards/StatisticsCards/MiniStatisticsCard";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MenuContext } from "context/menuContext";
import { useContext } from "react";
import SinDatos from "components/Carteles/SinDatos";

export default function Fases({ proyecto, setProyecto, large }) {
  const { menu } = useContext(MenuContext);
  return (
    <SoftBox>
      {large && (
        <SoftBox display="flex" justifyContent="space-between" mb={2}>
          <SoftTypography variant="h6">{`Nuevo proyecto - Fases`}</SoftTypography>
          <SoftButton
            circular
            color="primary"
            onClick={(event) => {
              menu({
                open: event.currentTarget,
                align: "right",
                options: [
                  {
                    name: "Nueva fase",
                    icon: { icon: "add", color: "success" },
                    onClick: () => {},
                  },
                  {
                    name: "Plantilla",
                    icon: { icon: "file_copy", color: "info" },
                    onClick: () => {},
                  },
                ],
                handleClose: () => {},
              });
            }}
          >
            <Icon>add</Icon>&nbsp;Agregar
          </SoftButton>
        </SoftBox>
      )}
      <SoftBox>
        <SinDatos />
      </SoftBox>
    </SoftBox>
  );
}

Fases.propTypes = {
  proyecto: PropTypes.object,
  setProyecto: PropTypes.func,
  large: PropTypes.bool,
};
