import { jsPDF } from "jspdf";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import {
  Card,
  Grid,
  Icon,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import { formatDate, formatMoneyPunto } from "utils/formatters";
import { gql, useLazyQuery } from "@apollo/client";
import DinamicCard from "components/Cards/InfoCards/DinamicCard";
import TableItem from "./TableItem";
import SinDatos from "components/Carteles/SinDatos";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import ArrowImg from "./resource/arrow.png";
import Logo from "assets/images/logo.png";
import EmpleadoLayout from "components/LayoutContainers/EmpleadoLayout";
import boxShadows from "assets/theme/base/boxShadows";
import QRCode from "react-qr-code";

const GeneratePdf = () => {
  const documentsRef = useRef(null);
  const { id } = useParams();
  const { lg } = boxShadows;

  const [data, setData] = useState(null);
  const [productosRecepcion, setProductosRecepcion] = useState([]);

  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;

  const qrRef = useRef();
  const [qrImage, setQrImage] = useState(null);

  const [getMovimiento] = useLazyQuery(
    gql`
      query getMovimientos($id: ID) {
        movimientos(filter: { id: $id }) {
          id
          estado
          tipo
          user {
            username
            nombre
            apellido
          }
          sucursalDestino {
            id
            nombre
            sede {
              id
              nombre
            }
          }
          sucursalOrigen {
            id
            nombre
            sede {
              id
              nombre
            }
          }
          observaciones
          movimientoDetalles {
            id
            precio
            cantidad
            producto {
              id
              codigo
              tipo
              nombre
              categoria
              precio
            }
          }
          recepcion {
            id
            user {
              username
              nombre
              apellido
            }
            observaciones
            movimientoDetalles {
              id
              producto {
                id
              }
              cantidad
            }
            createdAt
          }
          createdAt
        }
      }
    `,
    {
      onCompleted: (data) => {
        if (!data?.movimientos[0]) return;
        setData(data.movimientos[0]);
      },
    }
  );

  useEffect(() => {
    if (id) {
      getMovimiento({ variables: { id } });
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      document.title = `Remito n°${data.id}`;

      let productosCargados = data.movimientoDetalles.map((item) => ({
        ...item.producto,
        id: item.producto.id,
        enviado: -item.cantidad,
        recibido: data.recepcion?.movimientoDetalles?.find(
          (md) => md.producto.id == item.producto.id
        )?.cantidad,
      }));
      setProductosRecepcion(productosCargados);

      if(!data?.recepcion) {
        const svg = qrRef?.current?.querySelector("svg");
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        canvas.width = 256;
        canvas.height = 256;
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = () => {
          ctx.drawImage(img, 0, 0);
          setQrImage(canvas.toDataURL("image/png"));
        };
        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
      }
    }
  }, [data]);

  const handleGeneratePdf = async () => {
    const document = documentsRef.current.childNodes[0];

    const doc = new jsPDF({
      format: "a4",
      unit: "px",
      orientation: "portrait",
      hotfixes: ["px_scaling"],
    });

    await doc.html(document, {
      margin: [50, 0, 50, 0],
      callback(doc) {
        doc.save(`Remito n°${data.id}.pdf`);
      },
    });
  };

  const columnsSelect = [
    { name: "type", desc: " ", align: "left", width: "1%" },
    { name: "id", desc: "ID", align: "left" },
    { name: "nombre", desc: "Nombre", align: "left" },
    // { name: "categoria", desc: "Rubro", align: "left" },
    { name: "enviado", desc: "Enviado", align: "right" },
    { name: "recibido", desc: "Recibido", align: "right" },
    { name: "check", desc: " ", align: "right", width: "1%" },
  ];

  const renderColumnsSelect = columnsSelect.map(({ name, desc, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 1;
    } else if (key === columnsSelect.length - 1) {
      pl = 1;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SoftBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        {desc ? desc.toUpperCase() : name.toUpperCase()}
      </SoftBox>
    );
  });

  if (!data)
    return (
      <EmpleadoLayout>
        <Card>
          <SoftBox p={3}>
            <Grid container textAlign={"center"} py={3}>
              <Grid item xs={12}>
                <Icon fontSize="medium">search_off</Icon>
              </Grid>
              <Grid item xs={12}>
                <SoftTypography variant="h6">El documento seleccionado no existe</SoftTypography>
              </Grid>
              <Grid item xs={12}>
                <SoftTypography variant="caption" component="p" color="text">
                  El documento que buscas no existe o ha sido eliminado
                </SoftTypography>
              </Grid>
            </Grid>
          </SoftBox>
        </Card>
      </EmpleadoLayout>
    );

  return (
    <EmpleadoLayout>
      <SoftBox
        p={2}
        display="flex"
        alignItems="center"
        flexDirection="column"
        minHeight="100vh"
        height="100%"
      >
        <div ref={documentsRef}>
          <SoftBox
            sx={{
              position: "relative",
              overflow: "hidden",
              width: "794px",
              backgroundColor: "#ffffff",
              boxShadow: lg,
              px: 6,
            }}
          >
            {/* ACA VA TODO EL CODIGO */}
            <Grid container py={2}>
              <Grid item xs={6}>
                <img src={Logo} alt="Terra" width="25%" />
              </Grid>
              <Grid item xs={6} display="flex" flexDirection="column" gap={1} alignItems="end">
                <SoftTypography variant="caption">Universidad Provincial de Oficios</SoftTypography>
                <SoftTypography variant="caption">
                  Remito de transferencia entre sedes
                </SoftTypography>
              </Grid>
            </Grid>

            <SoftBox>
              <SoftBox px={1}>
                <SoftTypography variant="h6" fontWeight="bold">
                  Detalle de transferencia
                </SoftTypography>
              </SoftBox>
              <Grid container pt={3} pb={1} rowSpacing={1}>
                <Grid item xs={12} md={5.5}>
                  <DinamicCard
                    title={data?.sucursalOrigen?.sede?.nombre}
                    data={{
                      Movimiento: data?.id,
                      usuario: data?.user?.nombre
                        ? `${data?.user?.nombre} ${data?.user?.apellido}`
                        : data?.user?.username,
                      fecha: formatDate(data?.createdAt, "dd/MM/yyyy hh:mm"),
                      Observación: data?.observaciones,
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <img src={ArrowImg} width="25" />
                </Grid>
                <Grid item xs={12} md={5.5}>
                  {data?.recepcion ? (
                    <DinamicCard
                      title={data?.sucursalDestino?.sede?.nombre}
                      data={{
                        Movimiento: data?.recepcion?.id || "",
                        usuario: data?.recepcion?.user?.nombre
                          ? `${data?.recepcion?.user?.nombre} ${data?.recepcion?.user?.apellido}`
                          : data?.recepcion?.user?.username,
                        fecha: formatDate(data?.recepcion?.createdAt, "dd/MM/yyyy hh:mm") || "",
                      }}
                    />
                  ) : (
                    <DinamicCard title={data?.sucursalDestino?.sede?.nombre}>
                      <SoftBox>
                        <div ref={qrRef} style={{display: "none"}}>
                          <QRCode
                            value={window.location.hostname + "/recepcion/" + data?.id}
                            size={256}
                            fgColor="#344767"
                          />
                        </div>
                        <SoftBox display="flex" justifyContent="center" pb={1}>
                          <img src={qrImage} alt="qr" height="80pt" />
                        </SoftBox>
                        <SoftBox display="flex" justifyContent="center">
                          <SoftTypography variant="caption" color="secondary">
                            No recepcionado
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox display="flex" justifyContent="center">
                          <SoftTypography variant="caption" color="secondary" textAlign="center">
                            para recepcionar utilice el código QR o ingrese al sistema
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </DinamicCard>
                  )}
                </Grid>
              </Grid>
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                py={2}
                px={1}
              >
                <SoftBox>
                  <SoftTypography variant="h6" fontWeight="bold">
                    Productos
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
              <SoftBox
                sx={{
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                        `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                {productosRecepcion.length > 0 ? (
                  <TableContainer>
                    <Table>
                      <SoftBox component="thead">
                        <TableRow>{renderColumnsSelect}</TableRow>
                      </SoftBox>
                      <TableBody>
                        {productosRecepcion.map((material) => {
                          return (
                            <TableItem
                              key={material.id}
                              material={material}
                              disabled={false}
                              handleUpdateMaterial={() => {}}
                            />
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <SinDatos />
                )}
              </SoftBox>
            </SoftBox>
            <Grid container py={2}>
              <Grid item xs={3}></Grid>
              <Grid item xs={6} textAlign="center">
                <SoftTypography variant="caption" color="secondary">
                  © {new Date().getFullYear()} Powered by Kaizen Software Factory
                </SoftTypography>
              </Grid>
              <Grid item xs={3} textAlign="right">
                <SoftTypography variant="caption" color="secondary">
                  {formatDate(new Date(), "dd/MM/yyyy hh:mm")}
                </SoftTypography>
              </Grid>
            </Grid>
          </SoftBox>
        </div>
        <SoftBox
          variant="contained"
          bgColor="white"
          p={2}
          style={{
            position: "fixed",
            bottom: "1.5rem",
            right: "1.5rem",
            zIndex: 1000,
            boxShadow:
              "inset 0rem 0rem 0.0625rem 0.0625rem rgb(255 255 255 / 90%), 0rem 1.25rem 1.6875rem 0rem rgb(0 0 0 / 5%)",
            borderRadius: "16px",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            backdropFilter: `saturate(200%) blur(${pxToRem(30)})`,
          }}
        >
          <Grid container spacing={2} justifyContent="flex-end" id="button">
            <Grid item>
              <SoftButton variant="gradient" color="error" onClick={handleGeneratePdf}>
                <Icon fontWeight="bold">picture_as_pdf</Icon>
                &nbsp;&nbsp;Descargar PDF
              </SoftButton>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </EmpleadoLayout>
  );
};

export default GeneratePdf;
