// React
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// @mui material components
import { Card, Grid, Icon, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";

import { gql, useLazyQuery, useQuery } from "@apollo/client";
import SoftBox from "components/CheckBox";
import SoftInput from "components/SoftInput";
import SoftInputNumber from "components/SoftInputNumberUpgrade";
import SoftTypography from "components/SoftTypography";
import dayjs from "dayjs";
import PresupuestoPDF from "../PDF/Presupuesto";
import SoftButton from "components/SoftButton";
import ContratoLaboratorio from "../PDF/ContratoLaboratorio";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "60%", xxl: "50%" },
  overflowY: "auto",
  p: 3,
};

export default function Contrato({ estudio, setEstudio, contrato, setContrato }) {
  return (
    <SoftBox>
      <SoftBox display="flex" justifyContent="space-between" mb={2}>
        <SoftBox>
          <SoftTypography variant="h6">{`Nuevo Estudio - Contrato`}</SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12} sm={5}>
            <Card>
              <SoftBox p={3}>
                <Grid container spacing={2} alignItems="flex-start">
                  <Grid item xs={12} sm={4}>
                    <InputLabel htmlFor="version">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Versión
                        <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    <SoftInput
                      label="version"
                      placeholder="Ingrese la versión del estudio"
                      type="text"
                      name="version"
                      value={contrato?.version}
                      onChange={(e) => setContrato({ ...contrato, version: e.target.value })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <InputLabel htmlFor="fechaVigencia">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Fecha de vigencia
                        <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    <SoftInput
                      label="fechaVigencia"
                      type="date"
                      name="fechaVigencia"
                      value={contrato?.fechaVigencia}
                      onChange={(e) => setContrato({ ...contrato, fechaVigencia: e.target.value })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <InputLabel htmlFor="proximaRevision">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Fecha de próxima revisión
                        <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    <SoftInput
                      label="proximaRevision"
                      type="date"
                      name="proximaRevision"
                      value={contrato?.proximaRevision}
                      onChange={(e) => setContrato({ ...contrato, proximaRevision: e.target.value })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="motivo">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        En que consiste la investigación
                        <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    <SoftInput
                      label="motivo"
                      placeholder="Ingrese el motivo del estudio"
                      type="text"
                      name="motivo"
                      multiline
                      rows={4}
                      value={contrato?.motivo}
                      onChange={(e) => setContrato({ ...contrato, motivo: e.target.value })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="formulacion">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Formulación de prueba
                        <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    <SoftInput
                      label="formulacion"
                      placeholder="Ingrese el formulacion del estudio"
                      type="text"
                      name="formulacion"
                      value={contrato?.formulacion}
                      onChange={(e) => setContrato({ ...contrato, formulacion: e.target.value })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabel htmlFor="especie">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Especie
                        <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    <SoftInput
                      label="especie"
                      placeholder="Ingrese el especie donde se probará el estudio"
                      type="text"
                      name="especie"
                      value={contrato?.especie}
                      onChange={(e) => setContrato({ ...contrato, especie: e.target.value })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabel htmlFor="precio">
                      <SoftTypography component="label" variant="caption" fontWeight="bold">
                        Precio por muestra
                        <SoftTypography variant="caption" fontWeight="bold" color="primary">
                          &nbsp;*
                        </SoftTypography>
                      </SoftTypography>
                    </InputLabel>
                    <SoftInputNumber
                      label="precio"
                      placeholder="Ingrese el precio donde se probará el estudio"
                      name="precio"
                      value={contrato?.precio}
                      onChange={(e) => setContrato({ ...contrato, precio: e.target.value })}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Tooltip
                      placement="top"
                      title={
                        !contrato?.version ||
                        !contrato?.fechaVigencia ||
                        !contrato?.proximaRevision ||
                        !contrato?.precio ||
                        !contrato?.especie ||
                        !contrato?.precio ||
                        !contrato?.formulacion
                          ? "Debe completar todos los campos antes de continuar"
                          : ""
                      }
                    >
                      <SoftBox>
                        <SoftButton
                          color="primary"
                          circular
                          fullWidth
                          disabled={
                            !contrato?.version ||
                            !contrato?.fechaVigencia ||
                            !contrato?.proximaRevision ||
                            !contrato?.precio ||
                            !contrato?.especie ||
                            !contrato?.precio ||
                            !contrato?.formulacion
                          }
                        >
                          Guardar y Enviar&nbsp;<Icon>send</Icon>
                        </SoftButton>
                      </SoftBox>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <Tooltip
                      placement="top"
                      title={
                        !contrato?.version ||
                        !contrato?.fechaVigencia ||
                        !contrato?.proximaRevision ||
                        !contrato?.precio ||
                        !contrato?.especie ||
                        !contrato?.precio ||
                        !contrato?.formulacion
                          ? "Debe completar todos los campos antes de continuar"
                          : ""
                      }
                    >
                      <SoftBox>
                        <SoftButton
                          color="dark"
                          circular
                          fullWidth
                          disabled={
                            !contrato?.version ||
                            !contrato?.fechaVigencia ||
                            !contrato?.proximaRevision ||
                            !contrato?.precio ||
                            !contrato?.especie ||
                            !contrato?.precio ||
                            !contrato?.formulacion
                          }
                        >
                          Guardar y Salir&nbsp;<Icon>save</Icon>
                        </SoftButton>
                      </SoftBox>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                  <Tooltip
                      placement="top"
                      title={
                        !contrato?.version ||
                        !contrato?.fechaVigencia ||
                        !contrato?.proximaRevision ||
                        !contrato?.precio ||
                        !contrato?.especie ||
                        !contrato?.precio ||
                        !contrato?.formulacion
                          ? "Debe completar todos los campos antes de descargar el PDF"
                          : ""
                      }
                    >
                      <SoftBox>
                    <SoftButton
                      color="info"
                      circular
                      fullWidth
                      onClick={() => {
                        const link = document.getElementById("descargarPDF");
                        link.click();
                      }}
                      disabled={
                        !contrato?.version ||
                        !contrato?.fechaVigencia ||
                        !contrato?.proximaRevision ||
                        !contrato?.precio ||
                        !contrato?.especie ||
                        !contrato?.precio ||
                        !contrato?.formulacion
                      }
                    >
                      Descargar PDF&nbsp;<Icon>download</Icon>
                    </SoftButton>
                    </SoftBox>
                    </Tooltip>
                  </Grid>
                </Grid>
              </SoftBox>
            </Card>
          </Grid>
          <Grid item xs={12} sm={7}>
            <ContratoLaboratorio estudio={estudio} contrato={contrato} />
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

Contrato.propTypes = {
  estudio: PropTypes.object,
  setEstudio: PropTypes.func,
  contrato: PropTypes.object,
  setContrato: PropTypes.func,
};
