// React
import PropTypes from "prop-types";
import { useEffect } from "react";

// @mui material components
import { Grid, InputLabel, MenuItem, Select } from "@mui/material";

import { gql, useLazyQuery, useQuery } from "@apollo/client";
import SoftBox from "components/CheckBox";
import SoftInput from "components/SoftInput";
import SoftInputNumber from "components/SoftInputNumberUpgrade";
import SoftTypography from "components/SoftTypography";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "60%", xxl: "50%" },
  overflowY: "auto",
  p: 3,
};

export default function Cotizacion({ proyecto, setProyecto, large }) {
  const { data: veterinarias } = useQuery(
    gql`
      query getVeterinarias {
        veterinarias {
          id
          nombre
          apellido
        }
      }
    `
  );

  const { data: usuarios } = useQuery(
    gql`
      query getUsuarios {
        usuarios {
          id
          nombre
          apellido
        }
      }
    `
  );

  const { data: provincias } = useQuery(gql`
    query GetProvincias {
      provincias {
        id
        nombre
      }
    }
  `);

  const [getLocalidades, { data: localidades }] = useLazyQuery(gql`
    query GetLocalidades($provincia: ID!) {
      localidades(provincia: $provincia) {
        id
        nombre
        provincia {
          id
        }
      }
    }
  `);

  useEffect(() => {
    if (proyecto?.idProvincia) {
      getLocalidades({ variables: { provincia: proyecto?.idProvincia } }).then((data) => {
        if (!data?.data?.localidades?.find((localidad) => localidad.id === proyecto?.idLocalidad)) {
          let proyectoNew = { ...proyecto };
          delete proyectoNew.idLocalidad;
          setProyecto(proyectoNew);
        }
      });
    }
  }, [proyecto?.idProvincia]);

  return (
    <SoftBox>
      <SoftBox display="flex" justifyContent="space-between" mb={2}>
        <SoftBox>
          <SoftTypography variant="h6">{`Nuevo proyecto - Cotización`}</SoftTypography>
        </SoftBox>
      </SoftBox>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={large ? 3 : 6}>
          <InputLabel htmlFor="nombreContacto">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Nombre del contacto
            </SoftTypography>
          </InputLabel>
          <SoftInput
            label="nombreContacto"
            placeholder="Ingrese el nombre de la persona de contacto"
            type="text"
            name="nombreContacto"
            value={proyecto?.nombreContacto}
            onChange={(e) => setProyecto({ ...proyecto, nombreContacto: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={large ? 3 : 6}>
          <InputLabel htmlFor="ordenCompra">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Orden de compra
            </SoftTypography>
          </InputLabel>
          <SoftInput
            label="ordenCompra"
            placeholder="Ingrese el Nº de la orden de compra"
            type="text"
            name="ordenCompra"
            value={proyecto?.ordenCompra}
            onChange={(e) => setProyecto({ ...proyecto, ordenCompra: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputLabel htmlFor="nroContrato">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Número de contrato
            </SoftTypography>
          </InputLabel>
          <SoftInput
            label="nroContrato"
            placeholder="Ingrese el Nº de contrato"
            type="text"
            name="nroContrato"
            value={proyecto?.nroContrato}
            onChange={(e) => setProyecto({ ...proyecto, nroContrato: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <InputLabel htmlFor="costoEstimado">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Costo estimado
            </SoftTypography>
          </InputLabel>
          <SoftInputNumber
            label="costoEstimado"
            placeholder="Costo estimado del presupuesto"
            name="costoEstimado"
            value={proyecto?.costoEstimado}
            icon={{
              component: "attach_money",
              direction: "left",
            }}
            onChange={(e) => setProyecto({ ...proyecto, costoEstimado: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={large ? 3 : 4}>
          <InputLabel htmlFor="fechaInicioEstimada">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Fecha de inicio estimada
            </SoftTypography>
          </InputLabel>
          <SoftInput
            label="fechaInicioEstimada"
            type="date"
            name="fechaInicioEstimada"
            value={proyecto?.fechaInicioEstimada}
            onChange={(e) => setProyecto({ ...proyecto, fechaInicioEstimada: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={large ? 3 : 4}>
          <InputLabel htmlFor="fechaFinEstimada">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Fecha de fin estimada
            </SoftTypography>
          </InputLabel>
          <SoftInput
            label="fechaFinEstimada"
            type="date"
            name="fechaFinEstimada"
            value={proyecto?.fechaFinEstimada}
            error={dayjs(proyecto?.fechaInicioEstimada).isAfter(dayjs(proyecto?.fechaFinEstimada))}
            onChange={(e) => setProyecto({ ...proyecto, fechaFinEstimada: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} sm={large ? 3 : 4}>
          <InputLabel htmlFor="terminosPago">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Términos de pago
            </SoftTypography>
          </InputLabel>
          <Select
            name="terminosPago"
            value={proyecto?.terminosPago || -1}
            onChange={(e) => setProyecto({ ...proyecto, terminosPago: e.target.value })}
          >
            <MenuItem value={-1} disabled>
              Seleccione uno
            </MenuItem>
            <MenuItem value={"Pago inmediato"}>Pago inmediato</MenuItem>
            <MenuItem value={"Pago a 7 días"}>Pago a 7 días</MenuItem>
            <MenuItem value={"Pago a 15 días"}>Pago a 15 días</MenuItem>
            <MenuItem value={"Pago a 30 días"}>Pago a 30 días</MenuItem>
            <MenuItem value={"Pago a 45 días"}>Pago a 45 días</MenuItem>
            <MenuItem value={"Pago a 60 días"}>Pago a 60 días</MenuItem>
            <MenuItem value={"Pago a 90 días"}>Pago a 90 días</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={large ? 3 : 4}>
          <InputLabel htmlFor="importeDeposito">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Importe del Depósito
            </SoftTypography>
          </InputLabel>
          <SoftInputNumber
            label="importeDeposito"
            name="importeDeposito"
            placeholder="Ingrese el importe del depósito"
            value={proyecto?.importeDeposito}
            icon={{
              component: "attach_money",
              direction: "left",
            }}
            onChange={(e) => setProyecto({ ...proyecto, importeDeposito: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel htmlFor="notasExtra">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Notas extra
            </SoftTypography>
          </InputLabel>
          <SoftInput
            label="notasExtra"
            placeholder="Ingrese alguna nota extra (se mostrará en el presupuesto)"
            type="text"
            multiline
            rows={4}
            name="notasExtra"
            value={proyecto?.notasExtra}
            onChange={(e) => setProyecto({ ...proyecto, notasExtra: e.target.value })}
          />
        </Grid>
      </Grid>
    </SoftBox>
  );
}

Cotizacion.propTypes = {
  proyecto: PropTypes.object,
  setProyecto: PropTypes.func,
  large: PropTypes.bool,
};
