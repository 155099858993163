// React

// Components
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";

// @mui material components
import { Card, CircularProgress, Icon, Tab, Tabs } from "@mui/material";

import { gql, useMutation } from "@apollo/client";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import dayjs from "dayjs";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cotizacion from "../components/Cotizacion";
import Fases from "../components/Fases";
import ModalEnviarPresupuesto from "../components/ModalEnviarPresupuesto";
import ModalPresupuestoCreado from "../components/ModalPresupuestoCreado";
import EstudioDetalles from "../components/EstudioDetalles";
import Contrato from "../components/Contrato";

// Data

function NuevoProyecto() {
  const [estudio, setEstudio] = useState();
  const [contrato, setContrato] = useState({
    fechaVigencia: dayjs().format("YYYY-MM-DD"),
    proximaRevision: dayjs().add(1, "year").format("YYYY-MM-DD"),
    version: "1.0",
  });
  const [proyectoOld, setProyectoOld] = useState();
  const [openModalPresupuestoCreado, setOpenModalPresupuestoCreado] = useState(false);
  const [openModalEnviarPresupuesto, setOpenModalEnviarPresupuesto] = useState(false);
  const { handleSnackbar } = useContext(MessageManager);
  const [step, setStep] = useState(0);
  const steps = [
    {
      title: "Detalles del estudio",
      icon: "description",
    },
    {
      title: "Contrato",
      icon: "price_check",
    },
  ];
  const navigate = useNavigate();

  const [saveProyecto, { loading: loadingSave }] = useMutation(
    gql`
      mutation saveProyecto($input: ProyectoInput!) {
        saveProyecto(input: $input) {
          id
        }
      }
    `
  );

  const handleSave = () => {
    setOpenModalPresupuestoCreado(true);

    // saveProyecto({
    //   variables: {
    //     input: {
    //       ...estudio,
    //     },
    //   },
    // })
    //   .then(() => {
    //     estudio.id
    //       ? handleSnackbar("Proyecto actualizado correctamente", "success")
    //       : handleSnackbar("Proyecto creado correctamente", "success");
    //     refetch();
    //     navigate("/estudios");
    //   })
    //   .catch((e) => {
    //     handleSnackbar("Error al guardar el estudio", "error");
    //   });
  };

  const saveAndSend = () => {
    setOpenModalEnviarPresupuesto(true);

    // saveProyecto({
    //   variables: {
    //     input: {
    //       ...estudio,
    //     },
    //   },
    // })
    //   .then(() => {
    //     estudio.id
    //       ? handleSnackbar("Proyecto actualizado correctamente", "success")
    //       : handleSnackbar("Proyecto creado correctamente", "success");
    //     refetch();
    //     navigate("/estudios");
    //   })
    //   .catch((e) => {
    //     handleSnackbar("Error al guardar el estudio", "error");
    //   });
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox p={3}>
              <SoftBox pb={3}>
                {step === 0 && <EstudioDetalles estudio={estudio} setEstudio={setEstudio} large />}
                {step === 1 && (
                  <Contrato
                    estudio={estudio}
                    setEstudio={setEstudio}
                    contrato={contrato}
                    setContrato={setContrato}
                    large
                  />
                )}
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between" mt={2}>
                <SoftBox
                  mr={2}
                  sx={{
                    width: {
                      sm: "100%",
                      xl: "60%",
                      xxl: "30%",
                    },
                    display: {
                      xs: "none",
                      sm: "block",
                    },
                  }}
                >
                  <Tabs
                    value={step}
                    onChange={(e, value) => {
                      value <= step ? setStep(value) : null;
                    }}
                  >
                    {steps.map((thisStep, index) => (
                      <Tab
                        key={index}
                        icon={
                          <Icon
                            fontSize="small"
                            color={step === index ? "primary" : "dark"}
                            sx={{
                              verticalAlign: "middle",
                            }}
                          >
                            {thisStep.icon}
                          </Icon>
                        }
                        iconPosition="start"
                        label={
                          <SoftTypography
                            variant="body2"
                            fontWeight={step === index ? "bold" : "normal"}
                          >
                            {thisStep.title}
                          </SoftTypography>
                        }
                        sx={{
                          cursor: step < index ? "default" : "pointer",
                        }}
                      />
                    ))}
                  </Tabs>
                </SoftBox>
                <SoftBox
                  display={{
                    xs: "flex-row",
                    sm: "flex",
                  }}
                  justifyContent="end"
                  alignItems="center"
                >
                  <SoftBox
                    mr={{
                      xs: 0,
                      sm: 2,
                    }}
                    mt={{
                      xs: 2,
                      sm: 0,
                    }}
                  >
                    <SoftButton
                      color="primary"
                      circular
                      onClick={step === 0 ? () => navigate("/estudios") : () => setStep(step - 1)}
                      fullWidth={{
                        xs: true,
                        sm: false,
                      }}
                    >
                      <Icon sx={{ fontWeight: "light" }}>
                        {step === 0 ? "close" : "arrow_back"}
                      </Icon>
                      &nbsp;{step === 0 ? "Cancelar" : "Volver"}
                    </SoftButton>
                  </SoftBox>
                  <SoftBox
                    mt={{
                      xs: 2,
                      sm: 0,
                    }}
                  >
                    <SoftButton
                      color="kaizenPrimary"
                      circular
                      fullWidth={{
                        xs: true,
                        sm: false,
                      }}
                      onClick={step === 1 ? handleSave : () => setStep(step + 1)}
                      disabled={
                        loadingSave ||
                        (step === 0 &&
                          (!estudio?.nombre ||
                            !estudio?.codigo ||
                            !estudio?.laboratorio ||
                            !estudio?.responsable ||
                            !estudio?.provincia ||
                            !estudio?.localidad)) ||
                        (step === 1 &&
                          (!estudio?.version ||
                            !estudio?.fechaVigencia ||
                            !estudio?.proximaRevision ||
                            !estudio?.precio ||
                            !estudio?.especie ||
                            !estudio?.precio ||
                            !estudio?.formulacion))
                      }
                    >
                      {step === 1 ? "Guardar" : "Siguiente"} &nbsp;
                      {loadingSave ? (
                        <CircularProgress size={15} color="inherit" />
                      ) : (
                        <Icon sx={{ fontWeight: "light" }}>
                          {step === 1 ? "save" : "arrow_forward"}
                        </Icon>
                      )}
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>
        </SoftBox>
        <ModalPresupuestoCreado
          open={openModalPresupuestoCreado}
          handleClose={() => setOpenModalPresupuestoCreado(false)}
        />
        <ModalEnviarPresupuesto
          open={openModalEnviarPresupuesto}
          handleClose={() => setOpenModalEnviarPresupuesto(false)}
          estudio={estudio}
        />
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NuevoProyecto;
