// React
import { gql, useMutation, useQuery } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Components
import { Card, Grid, Icon, Menu, MenuItem, Tooltip } from "@mui/material";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import ModalProyecto from "./components/ModalProyecto";

// Data
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import CustomPagination from "components/Pagination";
import SoftInput from "components/SoftInput";
import DataTable from "components/Tables/DataTable";
import { MessageManager } from "context";
import { UserContext } from "context/user";
import dataProyectos from "./data/dataProyectos";
import { getKaizenColor } from "utils/colors";
import EstudioItem from "./components/EstudioItem";
import ModalVeterinarias from "layouts/veterinarias/ModalVeterinaria";
import ModalLaboratorios from "layouts/laboratorios/ModalLaboratorio";
import ModalEstudios from "./components/ModalEstudios";
import ModalAsignarVeterinaria from "./components/ModalAsignarVeterinaria";

function Estudios() {
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [selectedProyecto, setSelectedProyecto] = useState(null);
  const [openModalAsignarVeterinaria, setOpenModalAsignarVeterinaria] = useState(false);
  const [page, setPage] = useState(1);
  const [pagesInfo, setPagesInfo] = useState({
    pages: 0,
    total: 0,
  });
  const [orderBy, setOrderBy] = useState({
    campo: "nombre",
    orden: "ASC",
  });
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [selectedModalType, setSelectedModalType] = useState(null);
  const [openModalVeterinaria, setOpenModalVeterinaria] = useState(false);
  const [selectedVeterinaria, setSelectedVeterinaria] = useState(null);
  const [openModalLaboratorio, setOpenModalLaboratorio] = useState(false);
  const [selectedLaboratorio, setSelectedLaboratorio] = useState(null);
  const [openModalEstudios, setOpenModalEstudios] = useState(false);
  const [selectedEstudio, setSelectedEstudio] = useState(null);

  const { handleSnackbar } = useContext(MessageManager);
  const { verificarAcceso } = useContext(UserContext);
  const { columns, getRows } = dataProyectos;

  const handleOrderBy = (campo) => {
    if (orderBy.campo === campo) {
      if (orderBy.orden === "ASC") {
        setOrderBy({
          campo: campo,
          orden: "DESC",
        });
      } else {
        setOrderBy({
          campo: campo,
          orden: "ASC",
        });
      }
    } else {
      setOrderBy({
        campo: campo,
        orden: "ASC",
      });
    }
  };

  const handleOpenMenu = (event, item, type) => {
    setAnchorEl(event.currentTarget);
    setSelectedModalType(type);
    setOpenMenu(true);
    setSelectedProyecto(item);
  };

  const handleSearch = (event) => {
    const newTimeout = setTimeout(() => {
      setSearch(event.target.value);
    }, 500);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(newTimeout);
  };

  const { loading, error, data, refetch } = useQuery(
    gql`
      query getProyectos($search: String, $page: Int, $order: Order) {
        paginationInfo {
          pages
          total
        }
        proyectos(search: $search, pagination: { page: $page, limit: 10 }, order: $order) {
          id
          nombre
          veterinaria {
            id
            nombre
            apellido
          }
          vendedor {
            id
          }
          responsable {
            id
          }
          localidad {
            id
            provincia {
              id
            }
          }
          direccion
          estado
        }
      }
    `,
    {
      variables: {
        search: search || null,
        page: page,
        order: { field: orderBy.campo, order: orderBy.orden },
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (data) {
      setRows(getRows(data.proyectos, handleOpenMenu));
      setPagesInfo(data.paginationInfo);
    }
  }, [data]);

  const estudioDemo = [
    {
      id: 1,
      nombre: "Pulgicida para perros y gatos",
      estado: "Pendiente de asignación",
      laboratorio: {
        id: "4",
        nombre: "AnimalLAB",
        nombreResponsable: "Tomas",
        apellidoResponsable: "Alfonso",
        tipoDocumento: 96,
        documento: "42230113",
        documentoResponsable: "42230112",
        domicilio: "Tester Ville 23",
        telefono: "02656406608",
        correo: "tomasalfonsomassone@gmail.com",
        localidad: {
          id: "1687",
          nombre: "El Potrero",
          provincia: {
            id: "18",
            nombre: "Salta",
            __typename: "Provincia",
          },
          __typename: "Localidad",
        },
        __typename: "Laboratorio",
      },
      responsable: { id: 1, nombre: "Juan Perez" },
      localidad: { id: 1, nombre: "Merlo", provincia: { id: 1, nombre: "San Luis" } },
    },
    {
      id: 2,
      nombre: "Vacuna AC-23 para bovinos",
      estado: "En progreso",
      veterinaria: {
        id: "1",
        nombre: 'Veterinaria "El Guadal"',
        nombreResponsable: "Pepe",
        apellidoResponsable: "Alfonso",
        tipoDocumento: 96,
        documento: "42230119",
        documentoResponsable: "4112231",
        domicilio: null,
        telefono: "02656406608",
        correo: "tomasalfonsomassone@gmail.com",
        localidad: {
          id: "1477",
          nombre: "Cerro Azul",
          provincia: {
            id: "15",
            nombre: "Misiones",
            __typename: "Provincia",
          },
          __typename: "Localidad",
        },
        __typename: "Veterinaria",
      },
      laboratorio: {
        id: "6",
        nombre: "Horse Vet",
        nombreResponsable: "Tomas",
        apellidoResponsable: "Alfonso",
        tipoDocumento: 86,
        documento: "20422301195",
        documentoResponsable: "4211113",
        domicilio: "tester city 23",
        telefono: "02656406608",
        correo: "tomasalfonsomassone@gmail.com",
        localidad: {
          id: "1815",
          nombre: "Villa Mercedes",
          provincia: {
            id: "20",
            nombre: "San Luis",
            __typename: "Provincia",
          },
          __typename: "Localidad",
        },
        __typename: "Laboratorio",
      },
      responsable: { id: 1, nombre: "Tomas Alfonso" },
      localidad: { id: 1, nombre: "Villa Mercedes", provincia: { id: 1, nombre: "San Luis" } },
    },
    {
      id: 3,
      nombre: "Antiparasitario CL23-2 para equinos",
      estado: "Finalizado",
      veterinaria: {
        id: "2",
        nombre: "Veterinaria Patitas",
        nombreResponsable: "Juan",
        apellidoResponsable: "Perez",
        tipoDocumento: 80,
        documento: "20422301195",
        documentoResponsable: "42230119",
        domicilio: "Av. Norte 1490",
        telefono: "0265615406608",
        correo: "",
        localidad: {
          id: "1815",
          nombre: "Villa Mercedes",
          provincia: {
            id: "20",
            nombre: "San Luis",
            __typename: "Provincia",
          },
          __typename: "Localidad",
        },
        __typename: "Veterinaria",
      },
      laboratorio: {
        id: "5",
        nombre: "VetTech",
        nombreResponsable: "Tomas",
        apellidoResponsable: "Alfonso",
        tipoDocumento: 80,
        documento: "20-42230119-5",
        documentoResponsable: "42231111",
        domicilio: "Test 2031",
        telefono: "123123221",
        correo: "tomasalfonsomassone@gmail.com",
        localidad: {
          id: "1687",
          nombre: "El Potrero",
          provincia: {
            id: "18",
            nombre: "Salta",
            __typename: "Provincia",
          },
          __typename: "Localidad",
        },
        __typename: "Laboratorio",
      },
      responsable: { id: 1, nombre: "Rodrigo Silva" },
      localidad: { id: 1, nombre: "Merlo", provincia: { id: 1, nombre: "San Luis" } },
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <Grid container spacing={2} p={3}>
              <Grid item xs={12}>
                <SoftBox
                  display={{
                    xs: "flex-row",
                    sm: "flex",
                  }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                    <SoftTypography variant="h6">Estudios</SoftTypography>
                    <SoftBox
                      display={{
                        xs: "block",
                        sm: "none",
                      }}
                    >
                      <Tooltip title="Agregar nuevo proyecto" placement="top">
                        <Link to="/estudios/nuevo">
                          <SoftButton color="primary" circular iconOnly>
                            <Icon>add</Icon>
                          </SoftButton>
                        </Link>
                      </Tooltip>
                    </SoftBox>
                  </SoftBox>
                  <SoftBox
                    display="flex"
                    alignItems="center"
                    justifyContent="end"
                    mt={{
                      xs: 2,
                      sm: 0,
                    }}
                  >
                    <SoftBox
                      mr={{
                        xs: 0,
                        sm: 2,
                      }}
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "auto",
                        },
                      }}
                    >
                      <SoftInput
                        placeholder="Escribe aquí..."
                        icon={{ component: "search", direction: "left" }}
                        onChange={handleSearch}
                      />
                    </SoftBox>
                    <SoftBox
                      display={{
                        xs: "none",
                        sm: "block",
                      }}
                    >
                      <Tooltip title="Agregar nuevo estudio" placement="top">
                        <Link to="/estudios/nuevo">
                          <SoftButton color="primary" circular>
                            <Icon>add</Icon>
                            &nbsp;Agregar
                          </SoftButton>
                        </Link>
                      </Tooltip>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </Grid>
            </Grid>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {loading ? (
                <Loading />
              ) : estudioDemo.length > 0 ? (
                <Grid container spacing={2} p={3}>
                  {estudioDemo.map((item) => (
                    <Grid item xs={12} key={item.id}>
                      <EstudioItem
                        estudio={item}
                        handleEdit={(type) => {
                          setSelectedModalType(type);
                          setOpenModalAdd(true);
                          setSelectedEstudio(item);
                        }}
                        handleVeterinaria={() => {
                          setOpenModalVeterinaria(true);
                          setSelectedVeterinaria(item.veterinaria);
                        }}
                        handleLaboratorio={() => {
                          setOpenModalLaboratorio(true);
                          setSelectedLaboratorio(item.laboratorio);
                        }}
                        handleEstudios={() => {
                          setOpenModalEstudios(true);
                          setSelectedEstudio(item);
                        }}
                        handleAsignarVeterinaria={() => {
                          setOpenModalAsignarVeterinaria(true);
                          setSelectedEstudio(item);
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <SinDatos />
              )}
            </SoftBox>
            <CustomPagination
              loading={loading}
              length={data?.proyectos.length}
              total={pagesInfo?.total}
              pages={pagesInfo?.pages}
              page={page}
              setPage={setPage}
              noCard
            />
          </Card>
        </SoftBox>
        <ModalProyecto
          open={openModalAdd}
          handleClose={() => {
            setOpenModalAdd(false);
            setSelectedProyecto();
          }}
          refetch={refetch}
          selectedProyecto={selectedProyecto}
          tipo={selectedModalType}
        />
        <ModalVeterinarias
          open={openModalVeterinaria}
          handleClose={() => {
            setOpenModalVeterinaria(false);
            setSelectedVeterinaria();
          }}
          refetch={refetch}
          selectedUsuario={selectedVeterinaria}
        />
        <ModalLaboratorios
          open={openModalLaboratorio}
          handleClose={() => {
            setOpenModalLaboratorio(false);
            setSelectedLaboratorio();
          }}
          refetch={refetch}
          selectedUsuario={selectedLaboratorio}
        />
        <ModalEstudios
          open={openModalEstudios}
          handleClose={() => {
            setOpenModalEstudios(false);
            setSelectedEstudio();
          }}
          estudio={selectedEstudio}
        />
        <ModalAsignarVeterinaria
          open={openModalAsignarVeterinaria}
          handleClose={() => {
            setOpenModalAsignarVeterinaria(false);
            setSelectedEstudio();
          }}
          estudio={selectedEstudio}
        />
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Estudios;
