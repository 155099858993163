import SoftInput from "components/SoftInput";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useState } from "react";
import { formatMoneyPunto } from "utils/formatters";

export default function SoftInputNumber({value, onChange, ...rest}) {
  const [formatedValue, setFormatedValue] = useState("")


  useEffect(() => {
    if (value || value === 0) {
      let current = parseFloat(formatedValue.toString().replaceAll(".", "").replaceAll(",", "."));
      if (value.toString() !== current) {
        setFormatedValue(externalFormat(value.toString()));
      }
    } else {
      setFormatedValue("");
    }
  }, [value]);

  const externalFormat = (value) => {
    let integer = value.split(".")[0];
    let decimals = value.split(".")[1];

    let cifras = [];
    let i = integer.length;
    while (i > 0) {
      cifras.push(integer.substring(i - 3, i));
      i -= 3;
    }
    cifras.reverse();
    integer = cifras.join(".");
    return `${integer}${decimals ? "," + decimals : ""}`;
  };

  const interalFormat = (value) => {
    value = value.replaceAll(".", "");
    let integer = value.split(",")[0];
    let decimals = value.split(",")[1];

    let cifras = [];
    let i = integer.length;
    while (i > 0) {
      cifras.push(integer.substring(i - 3, i));
      i -= 3;
    }
    cifras.reverse();
    integer = cifras.join(".");
    return `${integer}${decimals ? "," + decimals : ""}`;
  };

  return (
    <SoftInput
      {...rest}
      value={formatedValue}
      onChange={(e) => {
        if (/^[0-9.,]*$/.test(e.target.value) === false) return;
        if (e.target.value.startsWith("0") && e.target.value.length > 1) return;
        if (e.target.value.startsWith(",")) return;
        if (e.target.value.match(/\,/g)?.length > 1) return;
        let val = e.target.value;
        if (val) {
          val = val.replace(/\./g, "");
          val = val.replace(/,/g, ".");
          val = parseFloat(val);
        } else {
          val = null;
        }
        onChange({ target: { value: val, name: e.target.name } });
        if (e.target.value.endsWith(",")) {
          setFormatedValue(e.target.value);
        } else {
          setFormatedValue(interalFormat(e.target.value));
        }
      }}
    />
  );
}

SoftInputNumber.defaultProps = {
  value: null,
  onChange: (e) => {},
};

SoftInputNumber.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
};
