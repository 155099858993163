// @mui material components
import { Card, Fade, Grid, Icon, Modal, Tooltip } from "@mui/material";
import PropTypes from "prop-types";

// Kaizen Dashboard components
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import SinDatos from "components/Carteles/SinDatos";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { MessageManager } from "context";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { useContext, useEffect, useRef, useState } from "react";
import { API_URL } from "services/config";
import { formatDate } from "utils/formatters";
import DocumentacionItem from "../DocumentacionItem";
import { UserContext } from "context/user";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "90%", xxl: "40%" },
  overflow: "auto",
};

export default function ModalDocumentacion({ open, handleClose, idveterinaria, readOnly }) {
  const { handleSnackbar } = useContext(MessageManager);
  const { verificarAcceso } = useContext(UserContext);
  const fileRef = useRef();
  const [files, setFiles] = useState([]);
  const [getNodo] = useLazyQuery(
    gql`
      query Veterinaria($id: ID) {
        veterinarias(filter: { id: $id }) {
          documentos {
            id
            nombre
            file
            createdAt
          }
        }
      }
    `,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (!data) return;
        let nodo = data.veterinarias[0];
        if (nodo) {
          setFiles(nodo.documentos);
        }
      },
    }
  );
  const [saveFile] = useMutation(
    gql`
      mutation add($idveterinaria: ID!, $file: Upload!) {
        add(idveterinaria: $idveterinaria, file: $file) {
          id
        }
      }
    `,
    {
      onCompleted: () => {
        handleSnackbar("Archivo subido correctamente", "success");
        getNodo({ variables: { id: idveterinaria } });
      },
      onError: (error) => {
        handleSnackbar(error.message, "error");
      },
    }
  );

  const [deleteFile] = useMutation(
    gql`
      mutation delete($id: ID!) {
        delete(id: $id)
      }
    `,
    {
      onCompleted: () => {
        handleSnackbar("Archivo eliminado correctamente", "success");
        getNodo({ variables: { id: idveterinaria } });
      },
      onError: (error) => {
        handleSnackbar(error.message, "error");
      },
    }
  );

  useEffect(() => {
    if (open && idveterinaria) {
      getNodo({ variables: { id: idveterinaria } });
    } else {
      setFiles([]);
    }
  }, [open]);

  const handleDownloadAll = async () => {
    const zip = new JSZip();

    for (let file of files) {
      const res = await fetch(`${API_URL}/${file.file}`);
      let filename = file.file.split("\\").pop();
      const blob = await res.blob();
      zip.file(filename, blob);
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "documentacion.zip");
    });
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Card sx={style}>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3} pb={0}>
              <SoftTypography variant="h6">Documentación</SoftTypography>
              <SoftBox display="flex" justifyContent="end" alignItems="center">
                <SoftBox mr={1}>
                  <Tooltip placement="top" title="Descargar todos">
                    <SoftButton
                      variant="gradient"
                      color="info"
                      iconOnly
                      circular
                      onClick={handleDownloadAll}
                      disabled={files.length < 1}
                    >
                      <Icon sx={{ fontWeight: "regular" }}>download</Icon>
                    </SoftButton>
                  </Tooltip>
                </SoftBox>
                {verificarAcceso(40) && (
                  <SoftBox mr={1}>
                    <Tooltip placement="top" title="Agregar nuevo">
                      <SoftButton
                        variant="gradient"
                        color="success"
                        iconOnly
                        circular
                        onClick={() => {
                          fileRef.current.value = null;
                          fileRef.current.click();
                        }}
                      >
                        <Icon sx={{ fontWeight: "regular" }}>add</Icon>
                      </SoftButton>
                    </Tooltip>
                    <input
                      ref={fileRef}
                      type="file"
                      style={{ display: "none" }}
                      accept=".pdf, .docx, .jpg, .jpeg, .png"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file && idveterinaria) {
                          saveFile({ variables: { idveterinaria: idveterinaria, file: file } });
                        }
                      }}
                    />
                  </SoftBox>
                )}
                <SoftBox>
                  <Tooltip placement="top" title="Cancelar">
                    <SoftButton
                      variant="gradient"
                      color="error"
                      iconOnly
                      circular
                      onClick={handleClose}
                    >
                      <Icon sx={{ fontWeight: "regular" }}>close</Icon>
                    </SoftButton>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            <SoftBox p={3}>
              <Grid container>
                <Grid item xs={12}>
                  <SoftBox>
                    <SoftBox
                      component="ul"
                      display="flex"
                      flexDirection="column"
                      p={0}
                      m={0}
                      sx={{ listStyle: "none" }}
                    >
                      {files?.map((file, index) => (
                        <DocumentacionItem
                          key={index}
                          name={file.nombre}
                          value={file.file}
                          description={formatDate(file.createdAt, "dd MMMM de yyyy, a las hh:mm")}
                          onDelete={() => {
                            deleteFile({ variables: { id: file.id } });
                          }}
                          readOnly={readOnly}
                        />
                      ))}
                      {files.length === 0 && <SinDatos />}
                    </SoftBox>
                  </SoftBox>
                </Grid>
              </Grid>
            </SoftBox>
          </Card>
        </Fade>
      </Modal>
    </>
  );
}

ModalDocumentacion.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  idveterinaria: PropTypes.string,
  readOnly: PropTypes.bool,
};
