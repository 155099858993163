// @mui material components
import { Card, Fade, Grid, Icon, Modal } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// Kaizen Dashboard components
import MiniStatisticsCard from "components/Cards/StatisticsCards/MiniStatisticsCard";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { getDocumentos } from "layouts/soporte/querys";
import ModalDocumentacion from "../ModalDocumentacion";
import ModalHilos from "../ModalHilos";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "70%", xxl: "50%" },
  overflow: "auto",
  py: 4,
};

export default function ModalAddIncidente({ open, handleClose, user, incidente }) {
  const [archivos, setArchivos] = useState([]);
  const [openDocumentacion, setOpenDocumentacion] = useState(false);
  const [openConversacion, setOpenConversacion] = useState(false);

  const handleOpenDocumentacion = () => {
    setOpenDocumentacion(true);
  };

  const handleCloseDocumentacion = () => {
    setOpenDocumentacion(false);
  };

  const handleOpenConversacion = () => {
    setOpenConversacion(true);
  }

  const handleCloseConversacion = () => {
    setOpenConversacion(false);
  }

  useEffect(() => {
    if (open) {
      obtenerDocumentos();
    }
  }, [open, incidente]);

  const obtenerDocumentos = () => {
    getDocumentos(incidente.id).then((response) => {
      setArchivos(response);
    });
  };

  // Recibe html en formato string y lo convierte a JSX
  const formatHTML = (html) => {
    let parser = new DOMParser();
    let doc = parser.parseFromString(html, "text/html");
    return <SoftBox dangerouslySetInnerHTML={{ __html: doc.body.innerHTML }} />;
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" px={3}>
            <SoftTypography variant="h6">Ver detalles del incidente</SoftTypography>
            <Icon
              sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
                fontSize: `${size.lg} !important`,
                fontWeight: `${fontWeightBold} !important`,
                stroke: dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
              })}
              onClick={handleClose}
            >
              close
            </Icon>
          </SoftBox>
          <SoftBox px={3} py={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <MiniStatisticsCard
                  title={{ text: "Nombre del incidente" }}
                  count={incidente?.tarea || "Sin especificar"}
                  icon={{ color: "primary", component: "bug_report" }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MiniStatisticsCard
                  title={{ text: "Estado" }}
                  count={incidente?.estadoTicketUser || "Sin especificar"}
                  icon={
                    incidente.estadoTicketUser === "Nuevo"
                      ? { color: "info", component: "new_releases" }
                      : incidente.estadoTicketUser === "Abierto"
                      ? { color: "dark", component: "menu_open" }
                      : incidente.estadoTicketUser === "Pendiente"
                      ? { color: "warning", component: "schedule" }
                      : incidente.estadoTicketUser === "En espera"
                      ? { color: "error", component: "pause" }
                      : incidente.estadoTicketUser === "Resuelto"
                      ? { color: "success", component: "done" }
                      : { color: "secondary", component: "assignment" }
                  }
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <MiniStatisticsCard
                  title={{ text: "Prioridad" }}
                  count={incidente?.prioridadSolicitanteUser || "Sin especificar"}
                  icon={
                    incidente.prioridadSolicitanteUser === "Baja"
                      ? { color: "info", component: "feedback" }
                      : incidente.prioridadSolicitanteUser === "Media"
                      ? { color: "warning", component: "priority_high" }
                      : incidente.prioridadSolicitanteUser === "Alta"
                      ? { color: "error", component: "warning" }
                      : { color: "secondary", component: "bug_report" }
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <SoftBox p={3}>
                    <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                      <SoftTypography variant="h6">Descripción del incidente</SoftTypography>
                      <Icon color="secondary">description</Icon>
                    </SoftBox>
                    <SoftBox pt={1}>
                      <SoftTypography variant="caption" lineHeight={1.8}>
                        {incidente?.descripcionTarea
                          ? formatHTML(incidente?.descripcionTarea)
                          : "Sin descripción"}
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </Card>
              </Grid>
            </Grid>
          </SoftBox>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" px={3}>
            <SoftBox display="flex" justifyContent="start">
              <SoftBox mr={2}>
                <SoftButton variant="gradient" color="primary" onClick={handleOpenDocumentacion}>
                  <Icon>link</Icon>&nbsp;
                  {archivos?.length > 0 && archivos.length < 2
                    ? `${archivos.length} Documento`
                    : archivos.length > 1
                    ? `${archivos.length} Documentos`
                    : "Documentación"}
                </SoftButton>
              </SoftBox>
              <SoftBox>
                <SoftButton variant="gradient" color="dark" onClick={handleOpenConversacion}>
                  <Icon>chat</Icon>&nbsp;
                  Conversación
                </SoftButton>
              </SoftBox>
            </SoftBox>
            <SoftBox display="flex" justifyContent="end" alignItems="center">
              <SoftBox>
                <SoftButton variant="gradient" color="primary" onClick={handleClose}>
                  Cerrar
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <ModalDocumentacion
            open={openDocumentacion}
            handleClose={handleCloseDocumentacion}
            item={archivos}
            setItem={setArchivos}
            incidente={incidente}
            refetch={obtenerDocumentos}
          />
          <ModalHilos
            open={openConversacion}
            handleClose={handleCloseConversacion}
            incidente={incidente}
          />
        </Card>
      </Fade>
    </Modal>
  );
}

ModalAddIncidente.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  incidente: PropTypes.object,
};
