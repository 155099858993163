import { Backdrop, Card, Fade, Icon, Modal, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { useContext, useEffect, useRef, useState } from "react";
// Kaizen Dashboard components
import axios from "axios";
import dayjs from "dayjs";
import Loading from "components/Carteles/Loading";
import SinDatos from "components/Carteles/SinDatos";
import ChatItem from "../ChatItem";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import { UserContext } from "context/user";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: "70%", md: "30%" },
  p: 3,
};

export default function ModalHilos({ open, handleClose, incidente }) {
  const [mensajes, setMensajes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [file, setFile] = useState(null);
  const inputRef = useRef(null);
  const fileRef = useRef(null);

  const toggleInput = () => {
    fileRef.current.value = null;
    fileRef.current.click();
  };

  const { user } = useContext(UserContext);

  const getMensajes = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://tickets.esamba.online/sistema-tickets-api/public/api/getHilosIncidente",
        {
          idIncidente: incidente.id,
        }
      );
      setMensajes(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const guardarArchivo = async () => {
    setLoading(true);
    if (file) {
      try {
        const response = await axios.post(
          "https://tickets.esamba.online/sistema-tickets-api/public/api/uploading-file-api",
          {
            file: file,
            tipoArchivo: "Tarea",
            idTarea: incidente?.id,
            nombreArchivo: file.name,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setLoading(false);
        setFile(null);
        return response.data.file;
      } catch (error) {
        console.log(error);
        setFile(null);
        setLoading(false);
      }
    } else {
      setLoading(false);
      setFile(null);
      return null;
    }
  };

  const addMensaje = async (mensaje) => {
    setLoading(true);
    const linkArchivo = await guardarArchivo();
    try {
      await axios.post(
        "https://tickets.esamba.online/sistema-tickets-api/public/api/addHiloIncidente",
        {
          idTarea: incidente.id,
          idUsuario: null,
          fecha: dayjs().format("YYYY-MM-DD HH:mm:ss"),
          mensaje: mensaje,
          linkArchivo: linkArchivo || null,
          nombreUsuarioExterno: user?.empleado
            ? `${user.empleado.nombre} ${user.empleado.apellido}`
            : null,
          dateAdd: dayjs().format("YYYY-MM-DD HH:mm:ss"),
          dateDel: null,
        }
      );
      getMensajes();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setMensaje("");
    setFile(null);
    if (open && incidente.id) {
      getMensajes();
      inputRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [open]);

  useEffect(() => {
    inputRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [mensajes]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style}>
          <SoftBox>
            <SoftTypography variant="h6">Conversación</SoftTypography>
          </SoftBox>
          <SoftBox
            py={3}
            sx={{
              maxHeight: "50vh",
              overflow: "auto",
            }}
          >
            {loading && <Loading />}
            {!loading && mensajes.length === 0 && <SinDatos />}
            {!loading &&
              mensajes.length > 0 &&
              mensajes.map((mensaje, index) =>
                index === mensajes.length - 1 ? (
                  <ChatItem
                    key={index}
                    image={
                      <Icon fontSize="small" color="primary">
                        person
                      </Icon>
                    }
                    title={mensaje.nombreUsuarioExterno || mensaje.empleado?.nombre}
                    ref={inputRef}
                    description={mensaje.mensaje}
                    date={dayjs(mensaje.dateAdd).format("DD-MM-YYYY HH:mm:ss")}
                    color={null}
                    type={mensaje.nombreUsuarioExterno ? "sent" : "received"}
                    adjunto={mensaje.linkArchivo}
                  />
                ) : (
                  <ChatItem
                    key={index}
                    image={
                      <Icon fontSize="small" color="primary">
                        person
                      </Icon>
                    }
                    title={mensaje.nombreUsuarioExterno || mensaje.empleado?.nombre}
                    description={mensaje.mensaje}
                    date={dayjs(mensaje.dateAdd).format("DD-MM-YYYY HH:mm:ss")}
                    color={null}
                    type={mensaje.nombreUsuarioExterno ? "sent" : "received"}
                    adjunto={mensaje.linkArchivo}
                  />
                )
              )}
          </SoftBox>
          <SoftBox pb={3} pt={3} display="flex">
            <SoftBox mr={2}>
              <Tooltip
                title={file ? "Eliminar archivo adjunto" : "Adjuntar archivo"}
                placement="top"
              >
                <SoftButton
                  variant="gradient"
                  color={file ? "error" : "dark"}
                  onClick={
                    file
                      ? () => {
                          setFile(null);
                        }
                      : () => {
                          toggleInput();
                        }
                  }
                  fullWidth
                  iconOnly
                  circular
                >
                  <Icon>{file ? "delete" : "attach_file"}</Icon>
                </SoftButton>
              </Tooltip>
              <SoftBox ml={3} sx={{ display: "none" }}>
                <input
                  type="file"
                  id="input-image"
                  ref={fileRef}
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </SoftBox>
            </SoftBox>
            <SoftBox
              sx={{
                width: "100%",
              }}
            >
              <SoftInput
                placeholder="Escribí acá"
                value={mensaje}
                onChange={(e) => setMensaje(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && mensaje !== "" && !loading) {
                    addMensaje(mensaje);
                    setMensaje("");
                  }
                }}
                icon={{
                  component: (
                    <Icon
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        if (mensaje !== "" && !loading) {
                          addMensaje(mensaje);
                          setMensaje("");
                        }
                      }}
                    >
                      send
                    </Icon>
                  ),
                  direction: "right",
                }}
              />
            </SoftBox>
          </SoftBox>
          <SoftBox display="flex" justifyContent="flex-end" alignItems="center">
            <SoftBox>
              <SoftButton variant="gradient" color="dark" onClick={handleClose}>
                Cancelar
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </Card>
      </Fade>
    </Modal>
  );
}

ModalHilos.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  incidente: PropTypes.object.isRequired,
};
