// @mui material components
import { useState, useEffect, useContext } from "react";
import { Modal, Fade, Card, Select, MenuItem, Grid, Icon } from "@mui/material";
import PropTypes from "prop-types";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import dayjs from "dayjs";
import { validateText } from "utils/validations";
import { gql, useMutation } from "@apollo/client";
import { MessageManager } from "context";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "40%", xxl: "30%" },
  overflow: "auto",
  p: 3,
};

const SAVE_RECORDATORIO = gql`
  mutation saveRecordatorio($recordatorio: RecordatorioInput!) {
    saveRecordatorio(input: $recordatorio) {
      id
      titulo
    }
  }
`;

const DELETE_RECORDATORIO = gql`
  mutation deleteRecordatorio($id: ID!) {
    deleteRecordatorio(id: $id) {
      id
    }
  }
`;

export default function ModalAlerta({ open, handleClose, recordatorios, setRecordatorios }) {
  const [postergar, setPostergar] = useState(false);
  const minDate = dayjs().subtract(-1, "day").format("YYYY-MM-DD");
  const [thisRecordatorios, setThisRecordatorios] = useState([]);
  const [saveRecordatorio] = useMutation(SAVE_RECORDATORIO);
  const [deleteRecordatorio] = useMutation(DELETE_RECORDATORIO);
  const { handleSnackbar } = useContext(MessageManager);

  const handleDelete = async (recordatorio) => {
    await deleteRecordatorio({
      variables: {
        id: recordatorio.id,
      },
    });
    handleSnackbar("Se eliminó con éxito el recordatorio", "success");
    const newRecordatorios = recordatorios.filter((item) => item.id !== recordatorio.id);
    setRecordatorios(newRecordatorios);
    handleClose();
  };

  const handleSave = async (recordatorio) => {
    const { data } = await saveRecordatorio({
      variables: {
        recordatorio: {
          id: recordatorio.id,
          fechaVencimiento: recordatorio.fechaVencimiento,
        },
      },
    });
    handleSnackbar("Se postergó con éxito el recordatorio", "success");
    const newRecordatorios = recordatorios.filter((item) => item.id !== recordatorio.id);
    setRecordatorios(newRecordatorios);
    handleClose();
  };

  useEffect(() => {
    setThisRecordatorios(recordatorios);
  }, [recordatorios]);

  useEffect(() => {
    setPostergar(false);
  }, [open]);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Card sx={style}>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center">
              <SoftTypography variant="h6">Tienes recordatorios para hoy</SoftTypography>
            </SoftBox>
            <SoftBox px={3} pt={3}>
              {thisRecordatorios.map((recordatorio, contador) => (
                <SoftBox key={contador} textAlign={"center"} p={3}>
                  <SoftBox>
                    <Icon fontSize="large">notifications</Icon>
                  </SoftBox>
                  <SoftBox>
                    <SoftTypography variant="h5">{recordatorio.titulo}</SoftTypography>
                  </SoftBox>
                  <SoftBox key={contador}>
                    <SoftTypography variant="h6" fontWeight="regular">
                      {recordatorio.descripcion}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox display="flex" justifyContent="center" pt={2}>
                    <SoftBox mr={2}>
                      {postergar ? (
                        <SoftInput
                          type="date"
                          label="Postergar"
                          value={recordatorio.fechaVencimiento}
                          inputProps={{
                            min: minDate,
                          }}
                          onChange={(e) =>
                            setThisRecordatorios(
                              thisRecordatorios.map((item) =>
                                item.id === recordatorio.id
                                  ? { ...item, fechaVencimiento: e.target.value }
                                  : item
                              )
                            )
                          }
                        />
                      ) : (
                        <SoftButton
                          variant="gradient"
                          color="dark"
                          onClick={() => setPostergar(true)}
                          circular
                        >
                          Postergar
                        </SoftButton>
                      )}
                    </SoftBox>
                    <SoftBox>
                      <SoftButton
                        variant="gradient"
                        color="primary"
                        onClick={
                          postergar
                            ? () => handleSave(recordatorio)
                            : () => handleDelete(recordatorio)
                        }
                        circular
                      >
                        {postergar ? "Aceptar" : "Descartar"}
                      </SoftButton>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              ))}
            </SoftBox>
          </Card>
        </Fade>
      </Modal>
    </>
  );
}

ModalAlerta.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  recordatorios: PropTypes.array.isRequired,
  setRecordatorios: PropTypes.func.isRequired,
};
