import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for the SoftBadge
import SoftBadgeRoot from "components/SoftBadge/SoftBadgeRoot";

const SoftBadge = forwardRef(
  ({ color, variant, size, circular, indicator, border, container, children, maxWidth, fullWidth, clickable, ...rest }, ref) => (
    <SoftBadgeRoot
      {...rest}
      ownerState={{ color, variant, size, circular, indicator, border, container, children, maxWidth }}
      ref={ref}
      color="default"
      sx={
        fullWidth && !clickable ? {
          width: "100%",
        } : fullWidth && clickable ? {
          width: "100%",
          cursor: "pointer",
          "&:hover": {
            opacity: 0.8,
            transform: "scale(1.02)",
          },
        } : !fullWidth && clickable ? {
          cursor: "pointer",
          "&:hover": {
            opacity: 0.8,
            transform: "scale(1.02)",
          },
        } : {}
      }
    >
      {children}
    </SoftBadgeRoot>
  )
);

// Setting default values for the props of SoftBadge
SoftBadge.defaultProps = {
  color: "primary",
  variant: "gradient",
  size: "sm",
  circular: false,
  indicator: false,
  border: false,
  children: false,
  container: false,
  fullWidth: false,
  clickable: false,
  maxWidth: false,
};

// Typechecking props of the SoftBadge
SoftBadge.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.oneOf(["gradient", "contained", "color", "colorGradient"]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
  circular: PropTypes.bool,
  indicator: PropTypes.bool,
  border: PropTypes.bool,
  children: PropTypes.node,
  container: PropTypes.bool,
  fullWidth: PropTypes.bool,
  clickable: PropTypes.bool,
  maxWidth: PropTypes.bool,
};

export default SoftBadge;
