// Kaizen Dashboard layouts
import ErrorHandler from "components/ErrorHandler";
import SignIn from "layouts/authentication/sign-in";
import Estudios from "layouts/estudios";
import NuevoEstudio from "layouts/estudios/NuevoEstudio";
import Soporte from "layouts/soporte";
import Usuarios from "layouts/usuarios";
import Veterinarias from "layouts/veterinarias";
// import Laboratorios from "layouts/authentication/laboratorios";
import Laboratorios from "layouts/laboratorios";

//PDFs
import RemitoSedePDF from "layouts/PDF/remitoSede";

// Kaizen Dashboard icons
import { Biotech, Pets, Science } from "@mui/icons-material";
import GroupIcon from "@mui/icons-material/Group";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import Recepcion from "layouts/recepcion";

const routes = [
  {
    type: "collapse",
    name: "Estudios",
    key: "estudios",
    route: "/estudios",
    icon: <Science />,
    component: <Estudios />,
    noCollapse: true,
    permiso: "Estudios",
  },
  {
    route: "/estudios/nuevo",
    component: <NuevoEstudio />,
    permiso: "Estudios",
  },
  // {
  //   type: "collapse",
  //   name: "Productos",
  //   key: "productos",
  //   route: "/productos",
  //   icon: <Inventory />,
  //   component: <Productos />,
  //   noCollapse: true,
  //   permiso: "Productos",
  // },
  {
    route: "/productos/pdf/:id",
    component: <RemitoSedePDF />,
  },
  {
    route: "/recepcion/:id",
    component: <Recepcion />,
  },
  // {
  //   type: "collapse",
  //   name: "Sucursal",
  //   key: "sucursal",
  //   route: "/sucursal",
  //   icon: <DashboardIcon />,
  //   component: <Sucursal />,
  //   noCollapse: true,
  //   permiso: "Sucursal",
  // },
  {
    type: "collapse",
    name: "Veterinarias",
    key: "veterinarias",
    route: "/veterinarias",
    icon: <Pets />,
    component: <Veterinarias />,
    noCollapse: true,
    permiso: "Veterinarias",
  },
  {
    type: "collapse",
    name: "Laboratorios",
    key: "laboratorios",
    route: "/laboratorios",
    icon: <Biotech />,
    component: <Laboratorios />,
    noCollapse: true,
    permiso: "Laboratorios",
  },
  {
    type: "collapse",
    name: "Usuarios",
    key: "usuarios",
    route: "/usuarios",
    icon: <GroupIcon />,
    component: <Usuarios />,
    noCollapse: true,
    permiso: "Usuarios",
  },
  {
    type: "collapse",
    name: "Soporte",
    key: "soporte",
    route: "/soporte",
    icon: <SupportAgentIcon />,
    component: <Soporte />,
    noCollapse: true,
  },
  {
    route: "/error",
    component: <ErrorHandler />,
  },
  {
    route: "/login",
    component: <SignIn />,
  },
  // {
  //   route: "/laboratorios",
  //   component: <Laboratorios />,
  // },
];

export default routes;
