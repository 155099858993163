/* eslint-disable react/prop-types */

import { gql, useQuery, useSubscription } from "@apollo/client";
import { useEffect, useRef } from "react";
import { createContext, useState } from "react";

const NotificationContext = createContext();

const NotificationProvider = ({ children }) => {
  //chats
  const {
    data: notificaciones,
    loading,
    error,
    refetch,
  } = useQuery(
    gql`
      query Notificaciones {
        notificaciones {
          id
          createdAt
          programmedAt
          header
          body
          url
        }
      }
    `
  );

  //subscription
  const { data } = useSubscription(
    gql`
      subscription SubNotificaciones {
        notificaciones {
          id
          createdAt
          programmedAt
          header
          body
          url
        }
      }
    `,
    {
      skip: loading || error,
    }
  );

  if ("Notification" in window) {
    // Comprueba si el navegador admite notificaciones.

    Notification.requestPermission().then(function (permission) {
      if (permission === "granted") {
        // El usuario ha otorgado permiso para recibir notificaciones.
        // Ahora puedes mostrar notificaciones.
      } else if (permission === "denied") {
        // El usuario ha denegado el permiso para recibir notificaciones.
        // Debes respetar esta decisión y no mostrar notificaciones.
      }
    });
  }

  useEffect(() => {
    if (data) {
      if (Notification.permission === "granted") {
        const notification = new Notification(
          "Tienes una nueva notificación: " + data.notificaciones.header,
          {
            body: data.notificaciones.body,
            icon: "/logo192.png",
          }
        );
      }
    }
  }, [data]);

  return <NotificationContext.Provider value={data}>{children}</NotificationContext.Provider>;
};

export { NotificationProvider, NotificationContext };
