// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Kaizen Dashboard base styles
import typography from "assets/theme/base/typography";
import { getVersion } from "services/config";
import { useEffect, useState } from "react";

function Footer() {
  const { size } = typography;
  const [version, setVersion] = useState();

  useEffect(() => {
    getVersion().then((response) => {
      setVersion(response.version);
    });
  }, []);

  return (
    <SoftBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
      sx={{
        userSelect: "none",
      }}
    >
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
      >
        &copy; {new Date().getFullYear()} created by
        <Link href="https://kaizensoftware.com.ar/" target="_blank">
          <SoftTypography variant="button" fontWeight="medium">
            &nbsp;Kaizen Software Factory
          </SoftTypography>
        </Link>
      </SoftBox>
      <SoftBox
        component="ul"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
        <SoftBox component="li" px={2} lineHeight={1}>
          <Link href="https://kaizensoftware.com.ar/" target="_blank">
            <SoftTypography variant="button" fontWeight="regular" color="text">
              Kaizen Software Factory
            </SoftTypography>
          </Link>
        </SoftBox>
        <SoftBox component="li" px={2} lineHeight={1}>
          <Link href="https://kaizensoftware.com.ar/" target="_blank">
            <SoftTypography variant="button" fontWeight="regular" color="text">
              Sobre nosotros
            </SoftTypography>
          </Link>
        </SoftBox>
        <SoftBox component="li" px={2} lineHeight={1}>
          <Link href="https://kaizensoftware.com.ar/" target="_blank">
            <SoftTypography variant="button" fontWeight="regular" color="text">
              Team
            </SoftTypography>
          </Link>
        </SoftBox>
        <SoftBox component="li" px={2} lineHeight={1}>
          <Link href="https://kaizensoftware.com.ar/" target="_blank">
            <SoftTypography variant="button" fontWeight="medium" color="text">
              Version {version}
            </SoftTypography>
          </Link>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
