// @mui material components
import { useState, useEffect, useContext } from "react";
import { Modal, Fade, Card, Select, MenuItem, Grid, Icon } from "@mui/material";
import PropTypes from "prop-types";

// Kaizen Dashboard components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { UserContext } from "context/user";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90%",
  width: { xs: "90%", sm: "30%", xxl: "20%" },
  overflow: "auto",
  py: 4,
};

export default function ModalOptions({ open, handleClose, options }) {
  const { verificarAcceso } = useContext(UserContext);
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Card sx={style}>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" px={3}>
              <SoftTypography variant="h6">Opciones</SoftTypography>
              <Icon
                sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
                  fontSize: `${size.lg} !important`,
                  fontWeight: `${fontWeightBold} !important`,
                  stroke: dark.main,
                  strokeWidth: "2px",
                  cursor: "pointer",
                })}
                onClick={handleClose}
              >
                close
              </Icon>
            </SoftBox>
            <SoftBox px={3} pt={3}>
              <Grid container spacing={2}>
                {options.map(
                  (option) =>
                    (!option.permiso || verificarAcceso(option.permiso)) && (
                      <Grid item xs={12} key={option.title}>
                        <SoftButton
                          color={option.color}
                          circular
                          onClick={(e) => {
                            option.onClick(e);
                          }}
                          fullWidth
                        >
                          <Icon sx={{ fontWeight: "bold" }}>{option.icon}</Icon>
                          &nbsp;&nbsp;{option.title}
                        </SoftButton>
                      </Grid>
                    )
                )}
              </Grid>
            </SoftBox>
          </Card>
        </Fade>
      </Modal>
    </>
  );
}

ModalOptions.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};
