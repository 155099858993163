/* eslint-disable react/prop-types */
import { Icon, Tooltip } from "@mui/material";
import SoftBadge from "components/SoftBadge";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { getKaizenColor } from "utils/colors";
import { makeNiceText } from "utils/formatters";

const getRows = (data, handleEdit) => {
  const rows = [];
  if (data) {
    data.forEach((item) => {
      rows.push({
        type: (
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
            data={
              <Icon
                sx={{
                  verticalAlign: "middle",
                  cursor: "pointer",
                  color: getKaizenColor("kaizenPrimary"),
                }}
                fontSize="lg"
              >
                person
              </Icon>
            }
            onClick={(e) => handleEdit(item)}
          >
            <SoftBox pl={3} mr={2}>
              <Tooltip title={"Estudio"} placement="top">
                <Icon
                  sx={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    color: getKaizenColor("kaizenPrimary"),
                  }}
                  fontSize="lg"
                >
                  science
                </Icon>
              </Tooltip>
            </SoftBox>
          </SoftBox>
        ),
        id: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.id}
            onClick={(e) => handleEdit(item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="bold"
            >
              {item?.id}
            </SoftTypography>
          </SoftBox>
        ),
        tipo: (
            <SoftBox
              sx={{
                cursor: "pointer",
              }}
              data={item?.tipoAnalisis}
              onClick={(e) => handleEdit(item)}
            >
              <SoftTypography
                verticalAlign="text-top"
                variant="caption"
                color="dark"
                fontWeight="bold"
              >
                {item?.tipoAnalisis}
              </SoftTypography>
            </SoftBox>
          ),
        sujeto: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.sujeto}
            onClick={(e) => handleEdit(item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="bold"
            >
              {item?.sujeto}
            </SoftTypography>
          </SoftBox>
        ),
        protocolo: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.protocolo}
            onClick={(e) => handleEdit(item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="dark"
              fontWeight="bold"
            >
              {item?.protocolo}
            </SoftTypography>
          </SoftBox>
        ),
        estado: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.estado}
            onClick={(e) => handleEdit(item)}
          >
            <SoftBadge badgeContent={makeNiceText(item?.estado)} color={item?.estado === "Finalizado" ? "success" : "warning"} />
          </SoftBox>
        ),

        fecha: (
          <SoftBox
            sx={{
              cursor: "pointer",
            }}
            data={item?.fecha}
            onClick={(e) => handleEdit(item)}
          >
            <SoftTypography
              verticalAlign="text-top"
              variant="caption"
              color="secondary"
              fontWeight="medium"
            >
              {item?.fecha}
            </SoftTypography>
          </SoftBox>
        ),
        accion: (
          <SoftBox
            mr={2}
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleEdit(item)}
          >
            <Tooltip title="Opciones" placement="top">
              <Icon
                sx={{ color: getKaizenColor("kaizenPrimary"), cursor: "pointer", mx: 0.5 }}
                fontSize="small"
                onClick={(e) => handleEdit(item)}
              >
                arrow_forward
              </Icon>
            </Tooltip>
          </SoftBox>
        ),
      });
    });
  }

  return rows;
};

const columns = [
  { name: "type", desc: " ", align: "left", width: "1%", noOrder: true },
  { name: "id", desc: "ID", width: "1%", align: "left", noOrder: true },
    { name: "tipo", desc: "Tipo de Análisis", align: "left", noOrder: true },
  { name: "sujeto", desc: "Sujeto", align: "left", noOrder: true },
  { name: "protocolo", desc: "Protocolo", align: "left", noOrder: true },
  { name: "estado", desc: "Estado", align: "center", noOrder: true },
  { name: "fecha", desc: "fecha", align: "left", noOrder: true },
  { name: "accion", desc: " ", align: "center", width: "1%", noOrder: true },
];

export default { columns, getRows };
